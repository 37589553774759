import React, { useEffect } from 'react';
import ViewReport from '../ViewReport';
import {
    Wrapper,
    HeaderWrapper,
    Span1,
    Span2,
    QueryPara,
    FlexWrapper,
    ChannelsText,
    TwitterText,
    StyledContainer,
    QueryPara2,
    TabHeading,
    Icon,
    DownloadWrapper,
    DateWrapper,
    CreateTopic,
} from '../ViewReport.styled';
import Overview from './ReportDownload/Overview';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from '../../../utils';
import { AmazonIconSmall, FacebookLogoSmall, FlipkartIcon, FlipkartLogo, Instagram, InstagramLogo, X_icon } from '../../../Common/SvgIcon/CommonSvgIcons';
import { fetchCreatedTopic } from '../../../store/redux/slices/topicSlice';
import Sentiments from './ReportDownload/Sentiments';
import ClusterAnalysisTab from './ReportDownload/ClusterAnalysisTab';
import Insights from './ReportDownload/Insights';
import OverViewTab from './ReportDownload/Chatter/OverViewTab';
import SentimentsTab from './ReportDownload/Chatter/SentimentsTab';
import InsightsTab from './ReportDownload/Chatter/InsightsTab';
import ClusterAnalysis from './ReportDownload/Chatter/ClusterAnalysisTab';
import OverviewTabInsta from './ReportDownload/OverviewTabInsta';
import ReviewsTab from '../../ActiveTopic/ReviewsTab/ReviewsTab';
import CommentsTab from '../../ChatterTracker/MainComponent/ChatterPreview/CommentsTab/CommentsTab';
import CustomAnalysisTab from './ReportDownload/CustomAnalysisTab';

const DownloadFile = ({
    data,
    job_id,
    email_id,
    created_at,
    is_topic = true,
    AnalysisFilter,
    formatValue
}) => {
    // console.log(AnalysisFilter, "AnalysisFilter");
    function convertDateTime(inputDateTime) {
        let dtObject = new Date(inputDateTime);
        const monthNames = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];

        let formattedDate =
            monthNames[dtObject.getMonth()] +
            ' ' +
            dtObject.getDate() +
            ' on ' +
            (dtObject.getHours() % 12 || 12) +
            ':' +
            (dtObject.getMinutes() < 10 ? '0' : '') +
            dtObject.getMinutes() +
            ' ' +
            (dtObject.getHours() >= 12 ? 'PM' : 'AM');

        return formattedDate;
    }
    const dispatch = useDispatch();

    let { getAllReportData } = useSelector((state) => state.sentinelHub) || [];

    let { topicPreviewData } = useSelector((state) => state.topicBuilder) || {};
    const { createdTopicData } = useSelector((state) => state.topicBuilder);

    let fetchedData = getAllReportData[0]?.report?.data;

    const overView_tab = fetchedData?.overview_tab || {};

    const sentiments_tab = fetchedData?.sentiments_tab || {};
    const insights_tab = fetchedData?.insights_tab || {};
    const clusterAnalysis_tab = fetchedData?.cluster_analysis_tab || {};

    const startDate = (getAllReportData[0]?.report?.start_date) || '2024/01/01';
    const endDate = (getAllReportData[0]?.report?.end_date) || '2024/01/02';
    const selectedTopic = createdTopicData.find(
        (topic) => topic.job_id === job_id
    );

    return (
        <Wrapper style={{ marginTop: '30px' }}>
            <HeaderWrapper>
                <Span1>{getAllReportData.length > 0 && (getAllReportData[0]?.report?.topic_title || getAllReportData?.length > 0 && getAllReportData[0]?.report?.title)}</Span1>
                <Span2>{getAllReportData.length > 0 && getAllReportData[0]?.report?.description}</Span2>
                <StyledContainer>
                    <ChannelsText>Channels:</ChannelsText>
                    <TwitterText>
                        <Icon>{
                            getAllReportData.length > 0 ?
                                getAllReportData[0]?.report?.is_topic == true ?
                                    getAllReportData[0]?.report?.channel == 'instagram' ?
                                        InstagramLogo
                                        :

                                        getAllReportData[0]?.report?.channel == 'facebook' ?
                                            FacebookLogoSmall
                                            :

                                            X_icon
                                    :
                                    getAllReportData[0]?.report?.channel == 'amazon' ?
                                        AmazonIconSmall
                                        :
                                        FlipkartLogo
                                :
                                <></>
                        }
                        </Icon> &nbsp; {getAllReportData.length > 0 && getAllReportData[0]?.report?.channel?.replace(/{|}/g, '')}
                    </TwitterText>
                </StyledContainer>
                {
                    getAllReportData.length > 0 && getAllReportData[0]?.report?.is_topic ?

                        <QueryPara>
                            <b>Query Used:&nbsp;</b> {getAllReportData.length > 0 && getAllReportData[0]?.report?.keywords_string}

                            <br />
                        </QueryPara>
                        :
                        <b style={{ color: 'rgb(53, 60, 247)', wordBreak: 'break-all' }}>{getAllReportData.length > 0 && getAllReportData[0]?.report?.hyperlink}</b>

                }
                {/* <br /> lang: en since: 2023-01-01 until: 2023-05-18 geocode: 20.5937,78.9629,2000 km */}

            </HeaderWrapper>
            <DownloadWrapper>
                <div className='created_at'>
                    {is_topic ? 'Topic ' : 'Chatter '}
                    Created at {convertDateTime(created_at)}
                </div>

            </DownloadWrapper>
            {
                AnalysisFilter?.Overviews?.value && is_topic ?
                    getAllReportData[0]?.report?.channel == 'instagram' ?
                        <OverviewTabInsta
                            overview_tab={overView_tab}
                            startDate={getAllReportData[0]?.report?.start_date}
                            filteredStartDate={startDate}
                            filteredEndDate={endDate}
                            endDate={getAllReportData[0]?.report?.end_date}
                            child={AnalysisFilter?.Overviews?.child}
                            hideDropdown={true}
                            formatValue={formatValue}
                        // filterOption={filterOption}
                        // filteredData={filteredData?.report?.data?.overview_tab}
                        // resetFlag={resetFlag}
                        // view={view}
                        />
                        :
                        <Overview
                            channel={getAllReportData?.[0]?.report?.channel}
                            overview_tab={overView_tab}
                            startDate={startDate}
                            endDate={endDate}
                            child={AnalysisFilter?.Overviews?.child}
                            hideDropdown={true}
                            formatValue={formatValue}
                        />
                    :
                    AnalysisFilter?.Overviews?.value ?
                        <OverViewTab
                            plan={"BrandAstraPro"}
                            selectedReport={getAllReportData[0]?.report}
                            child={AnalysisFilter?.Overviews?.child}
                            hideDropdown={true}
                            formatValue={formatValue}
                            startDate={startDate}
                            endDate={endDate}
                        />
                        :
                        <></>
            }
            {/* ,pageBreakAfter: 'always' */}
            {/* className="pdf-page"  style={{ pageBreakBefore: 'always', pageBreakInside: 'avoid' }} */}
            <div>
                {
                    AnalysisFilter?.Sentiments?.value && is_topic ?
                        <Sentiments
                            sentiments_tab={getAllReportData[0]?.report}

                            child={AnalysisFilter?.Sentiments?.child}
                            hideDropdown={true}

                            formatValue={formatValue}
                            startDate={startDate}
                            endDate={endDate}
                        />
                        :
                        AnalysisFilter?.Sentiments?.value ?
                            <SentimentsTab
                                plan={"BrandAstraPro"}
                                hideDropdown={true}
                                selectedReport={getAllReportData[0]?.report}
                                child={AnalysisFilter?.Sentiments?.child}

                                formatValue={formatValue}
                                startDate={startDate}
                                endDate={endDate}
                            />
                            :
                            <></>
                }
            </div>
            <div className="pdf-page">
                {
                    AnalysisFilter?.Insights?.value && is_topic ?
                        <Insights
                            insights_data={insights_tab}
                            child={AnalysisFilter?.Insights?.child}
                            hideDropdown={true}

                            formatValue={formatValue}
                            startDate={startDate}
                            endDate={endDate}
                        /> :
                        AnalysisFilter?.Insights?.value ?
                            <InsightsTab
                                // review_liked
                                plan={"BrandAstraPro"}
                                hideDropdown={true}
                                selectedReport={getAllReportData[0]?.report}
                                child={AnalysisFilter?.Insights?.child}

                                formatValue={formatValue}
                                startDate={startDate}
                                endDate={endDate}
                            />
                            :
                            <></>
                }
            </div>
            <div className="pdf-page">
                {
                    AnalysisFilter?.Cluster_Analysis?.value && is_topic ?
                        <ClusterAnalysisTab
                            cluster_analysis={clusterAnalysis_tab}
                            formatValue={formatValue}
                            startDate={startDate}
                            endDate={endDate}
                            child={AnalysisFilter?.Cluster_Analysis?.child}
                        />
                        :
                        AnalysisFilter?.Cluster_Analysis?.value ?
                            <ClusterAnalysisTab
                                cluster_analysis={clusterAnalysis_tab}
                                formatValue={formatValue}
                                startDate={startDate}
                                endDate={endDate}
                                child={AnalysisFilter?.Cluster_Analysis?.child}
                            />
                            // <ClusterAnalysis
                            //     plan={"BrandAstraPro"}
                            //     child={AnalysisFilter?.Cluster_Analysis?.child}
                            //     // selectedReport={getAllReportData[0]?.report}
                            //     selectedReport={getAllReportData[0]?.report?.data?.cluster_analysis_tab}
                            // />
                            :
                            <></>
                }
            </div>
            {
                AnalysisFilter?.Custom_Analysis?.value ?
                    <CustomAnalysisTab
                        AnalysisFilter={AnalysisFilter?.Custom_Analysis?.localVal}
                        job_id={job_id}
                        preview_uuid={topicPreviewData?.preview_uuid}
                    />
                    :
                    <></>
            }

            {/* {AnalysisFilter?.Reviews?.value && is_topic ?
                <ReviewsTab
                    download={true}
                    startDate={getAllReportData[0]?.report?.start_date}
                    endDate={getAllReportData[0]?.report?.end_date}
                    // preview_uuid={topicPreviewData?.preview_uuid}
                    job_id={data?.job_id}
                    email_id={getAllReportData[0]?.email_id}
                    channel={
                        getAllReportData[0]?.report?.channel
                    }
                />
                :
                <CommentsTab

                    download={true}

                    job_id={data?.job_id}
                    email_id={getAllReportData[0]?.email_id}
                    // job_id={job_id}
                    channelName={
                        getAllReportData[0]?.report?.channel
                    }
                    startDate={getAllReportData[0]?.report?.start_date}
                    endDate={getAllReportData[0]?.report?.end_date}
                />
            } */}
        </Wrapper>
    );
};


export default DownloadFile;