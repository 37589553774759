import React, { useEffect, useRef, useState } from 'react';
import {
  Wrapper,
  HeaderWrapper,
  Span1,
  Span2,
  QueryPara,
  FlexWrapper,
  ChannelsText,
  TwitterText,
  StyledContainer,
  QueryPara2,
  TabHeading,
  Icon,
  DownloadWrapper,
  DateWrapper,
  CreateTopic,
} from './ViewReport.styled';
import html2pdf from 'html2pdf.js';

import { AmazonIconSmall, FlipkartIconSmall, X_icon, download_icon } from '../../Common/SvgIcon/CommonSvgIcons';

import { useSelector, useDispatch } from 'react-redux';
import InsightsTab from './ChatterReport/InsightsTab';
import CommentsTab from '../ChatterTracker/MainComponent/ChatterPreview/CommentsTab/CommentsTab';
import ClusterAnalysisTab from './ChatterReport/ClusterAnalysisTab';
import { formatDate } from '../../utils';
import GenerateAnalysis from '../../components/Generate Analysis/GenerateAnalysis';

import {
  fetchCreatedTopic,
  createNewTopic,
  activeTopicList,
} from '../../store/redux/slices/topicSlice';

import { getReport } from '../../store/redux/slices/sentinelHubSlice';

import { useNavigate } from 'react-router-dom';
import OverViewTab from './ChatterReport/OverviewTab';
import SentimentsTab from './ChatterReport/SentimentsTab';
import DownloadFile from './Download/Download';
import CustomAnalysisTab from './Download/ReportDownload/CustomAnalysisTab';



function convertDateTime(inputDateTime) {
  let dtObject = new Date(inputDateTime);

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  let formattedDate =
    monthNames[dtObject.getMonth()] +
    ' ' +
    dtObject.getDate() +
    ' on ' +
    (dtObject.getHours() % 12 || 12) +
    ':' +
    (dtObject.getMinutes() < 10 ? '0' : '') +
    dtObject.getMinutes() +
    ' ' +
    (dtObject.getHours() >= 12 ? 'PM' : 'AM');

  return formattedDate;
}

const ViewReportsChatter = ({
  data,
  job_id,
  report_id,
  email_id,
  handleCancelClick,
  created_at,
  is_topic = true,
  hideDropdown
}) => {
  const [AnalysisFilter, setAnalysisFilter] = useState('')
  const [activeTab, setActiveTab] = useState('overViewTab');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [download, setDownload] = useState(false);
  const contentDivRef = useRef(null);

  const [selectedReport, setSelectedReport] = useState(null)
  const { companyBasicDetailsData, teamMemberListData } = useSelector(
    (state) => state.tenantSetting
  );

  let { topicPreviewData } = useSelector((state) => state.topicBuilder) || {};
  // const topicPreviewData = data;
  //   const { created } = useSelector((state) => state.topicBuilder);

  let { getAllReportData } = useSelector((state) => state.sentinelHub) || [];

  let render_string = '{}';
  let outlook_report = {};

  if (getAllReportData.find((el) => el.email_id === email_id)) {
    let tempData = getAllReportData.find((el) => el.email_id === email_id);
    render_string = tempData?.render_field_string;
    outlook_report = tempData?.report;
  } else {
    dispatch(getReport(email_id));
  }

  let fetchedData;

  //   const hyperlink = selectedReport
  //     ? selectedReport?.hyperlink
  //     : previewData?.hyperlink;

  const selectedTopic = []

  if (typeof job_id === 'number' && selectedTopic !== undefined) {
    topicPreviewData = selectedTopic;
    fetchedData = selectedTopic?.data;
  } else if (typeof email_id === 'string') {
    topicPreviewData = outlook_report;
  }
  fetchedData = topicPreviewData;


  // useEffect(() => {
  //   if (typeof report_id === 'number') {
  //     dispatch(getReport(report_id)).then((data) => {
  //       setSelectedReport(data.payload.data);
  //     })
  //   }

  // }, [])

  useEffect(() => {
    if (getAllReportData?.length > 0 && getAllReportData?.[0]?.render_field_string) {
      try {
        const parsedFilter = JSON.parse(getAllReportData[0].render_field_string);
        setAnalysisFilter(parsedFilter);  // Set the parsed object to state
      } catch (error) {
        console.error('Failed to parse render_string:', error);
        setAnalysisFilter(null);
      }
    } else {
      console.warn('No valid render_string found');
      setAnalysisFilter(null);
    }
  }, [getAllReportData]);


  //   const overView_tab = fetchedData?.overview_tab || {};
  //   const sentiments_tab = fetchedData?.sentiments_tab || {};
  //   const insights_tab = fetchedData?.insights || {};
  //   const clusterAnalysis_tab = fetchedData?.cluster_analysis || {};

  //   const startDate =
  //     formatDate(overView_tab?.post_date?.start_date) || '2024/01/01';
  //   const endDate = formatDate(overView_tab?.post_date?.end_date) || '2024/01/02';

  const renderTabContent = () => {
    switch (activeTab) {
      case 'overViewTab':
        return (
          <OverViewTab
            selectedReport={fetchedData}
            chatterData={fetchedData}
            plan={companyBasicDetailsData?.plan}
            hideDropdown={hideDropdown}
          /* chatterReport={report} */
          />
        );
      case 'sentimentsTab':
        return (
          <SentimentsTab
            selectedReport={fetchedData}
            plan={companyBasicDetailsData?.plan}
            hideDropdown={hideDropdown}
          /* chatterReport={report} */
          />
        );
      case 'insightTab':
        return (
          <InsightsTab
            showTableView={fetchedData?.channel == 'flipkart' ? true : false}
            insightTabData={fetchedData}
            plan={companyBasicDetailsData?.plan}
            /* chatterReport={report} */
            selectedReport={fetchedData}
            hideDropdown={hideDropdown}
          />
        );
      case 'clusterAnalysis':
        return (
          <ClusterAnalysisTab
            data={fetchedData?.data?.cluster_analysis_tab}
            selectedReport={fetchedData?.data?.cluster_analysis_tab
            }
            startDate={fetchedData?.start_date}
            endDate={fetchedData?.end_date}
            moduleType={fetchedData?.channel}
          />
        );
      case 'customTab':
        return (
          <CustomAnalysisTab
            showCustom={false}
            AnalysisFilter={AnalysisFilter?.Custom_Analysis?.localVal}
            // formatValue={formatValue}
            // job_id={job_id}
            // preview_uuid={topicPreviewData?.preview_uuid}
          />
        );
      case 'commentsTab':
        return (<CommentsTab
          download={true}
          email_id={getAllReportData[0]?.email_id}
          channelName={fetchedData?.channel}
          job_id={data?.job_id}
          hideDropdown={hideDropdown}
        />);
      default:
        return null;
    }
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleGeneratePdf = (refsArray) => {
    setDownload(true)
    const element = document.getElementById('element-to-convert');


    setAnalysisFilter(getAllReportData?.length > 0 && JSON.parse(getAllReportData[0]?.render_field_string))


    const options = {
      filename: 'myfile.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'a3', orientation: 'portrait' }
    };
    setTimeout(() => {


      // Call the html2pdf function with the selected element and options
      html2pdf().from(element).set(options).save()
        .then((e) => setDownload(false))

    }, 3000); // 3000 milliseconds = 3 seconds

  }

  return (
    <Wrapper>
      <HeaderWrapper>
        <Span1>{topicPreviewData?.title || topicPreviewData?.chatter_title}</Span1>
        <Span2>{topicPreviewData?.description}</Span2>
        <StyledContainer>
          <ChannelsText>Channels:</ChannelsText>
          <TwitterText>
            <Icon>{topicPreviewData?.is_topic ? X_icon :
              topicPreviewData?.channel == 'flipkart' ?
                FlipkartIconSmall :
                AmazonIconSmall}</Icon> &nbsp; {topicPreviewData?.channel?.replace(/{|}/g, '')}
          </TwitterText>
        </StyledContainer>
        {
          topicPreviewData?.is_topic == true ?

            <QueryPara>
              <b>Query Used:&nbsp;</b> {topicPreviewData?.keywords_string}

              <br />
            </QueryPara>

            :
            <b style={{ color: 'rgb(53, 60, 247)', wordBreak: 'break-all' }}>{topicPreviewData?.hyperlink}</b>

        }
        {/* <br /> lang: en since: 2023-01-01 until: 2023-05-18 geocode: 20.5937,78.9629,2000 km */}

      </HeaderWrapper>
      <DownloadWrapper>
        <div className='created_at'>
          {is_topic ? 'Topic ' : 'Chatter '}
          Created at {convertDateTime(created_at)}
        </div>

        <div className='buttonContainer'>
          <button className='button cancel' onClick={handleCancelClick}>
            Cancel
          </button>

          <button className='button'
            onClick={() => handleGeneratePdf(contentDivRef)}>
            {
              download ?
                '...downloading'
                :
                <>Download {download_icon}</>
            }

          </button>
        </div>
      </DownloadWrapper>
      <TabHeading>
        <div
          className={activeTab === 'overViewTab' ? 'activePlanTab' : ''}
          onClick={() => handleTabClick('overViewTab')}
        >
          Overviews
        </div>
        <div
          className={activeTab === 'sentimentsTab' ? 'activePlanTab' : ''}
          onClick={() => handleTabClick('sentimentsTab')}
        >
          Sentiments
        </div>
        <div
          className={activeTab === 'insightTab' ? 'activePlanTab' : ''}
          onClick={() => handleTabClick('insightTab')}
        >
          Insights
        </div>
        <div
          className={activeTab === 'clusterAnalysis' ? 'activePlanTab' : ''}
          onClick={() => handleTabClick('clusterAnalysis')}
        >
          Cluster Analysis
        </div>
        <div
          className={activeTab === 'customTab' ? 'activePlanTab' : ''}
          onClick={() => handleTabClick('customTab')}
        >
          Custom Analysis
        </div>
        <div
          className={activeTab === 'commentsTab' ? 'activePlanTab' : ''}
          onClick={() => handleTabClick('commentsTab')}
        >
          Reviews
        </div>
      </TabHeading>
      {renderTabContent()}

      <div style={{ display: 'none' }}>
        <div ref={contentDivRef} id="element-to-convert">
          <DownloadFile
            AnalysisFilter={AnalysisFilter}
            email_id={data?.email_id + ''}
            created_at={data.creation_time}
            is_topic={data.is_topic}

            data={data}

          />
        </div>

      </div>

    </Wrapper>
  );
};

export default ViewReportsChatter;
