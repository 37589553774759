import React, { useState, useEffect } from 'react';
import {
  Preview,
  Wrapper,
  Text,
  ColumnWrapper,
  MainContainer,
  QueryBuilderTitle_with_PreviewButton,
  TopicText,
  KeywordWrapper,
  Label,
  SubLabel,
  ErrorMessage,
  LoadingWrapper,

  LabelStyle,
  LabelParaStyle,
  FlexAlertWrapper,
  ShowAlertWrapper
} from './TopicBuilder.styled';
import Input from '../../../SettingPage/Input';
import Description from '../../../../Common/DescriptionInput/Description';
import Select from '../../../../Common/Select/Select';
import MultipleCountriesSelect from '../../../../Common/MultiSelect/MultipleSelect';
import LocationOrigin from '../Location';
import QueryString from '../QueryString/QueryString';
import ItemList from '../ToggleList/ToggleList';
import ConnectedChannels from '../ConnectedChannels/ConnectedChannels';

import { useDispatch, useSelector } from 'react-redux';
import { topicPreview } from '../../../../store/redux/slices/topicSlice';
import { useNavigate } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';
import { Switch } from '@mui/material';
import { languages } from '../../../../utils/languageList';
import { countries } from '../../../../utils/countryList';
import { setMessage } from '../../../../store/redux/slices/alertMessageSlice';

const toggle = [
  { id: 1, name: 'Include Sensitive Contents ', isActive: false },
  { id: 2, name: 'Only Posts with Media', isActive: false },
  { id: 3, name: 'Fetch Reposts', isActive: false },
  { id: 4, name: 'Only Posts from Verified Users', isActive: false },
];

const timeframe = [
  'None',
  'Last 1 month',
  'Last 3 month',
  'Last 6 month',
  'Last 12 month',
];


const StyledSwitch = styled(Switch)(() => ({
  width: '66px !important',
  height: '33px !important',
  padding: 6,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    transform: 'translateY(6px) translateX(6px)',
    '&.Mui-checked': {
      color: 'red',
      transform: 'translateY(6px) translateX(45px)',
      // transform: 'translateX(40px)',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#B4B7FC',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#353CF7',
  },
}));

function TopicBuilder() {
  const [inputValue, setInputValue] = useState('');
  const [desc, setDesc] = useState('');
  const [channel, setChannel] = useState('');

  const [keywordType, setKeywordType] = useState(['Word', 'Phrase', 'Hashtags', 'Mentions']);
  const [selectedLanguage, setSelectedLanguage] = useState([{ label: "English", value: "en" }]);
  const [selectedNotifyMethod, setSelectedNotifyMethod] = useState([{ label: "What's up", value: 'whatsup' }]);
  const [perForAlert, setPerForAlert] = useState([{ label: 'Mention Frequency', value: 'Mention Frequency' }]);
  const [scanFreq, setScanFreq] = useState([{ label: 'Daily', value: 'Daily' }]);
  const [showAlert, setShowAlert] = useState(false);
  const lang = true;

  const [queryString, setQueryString] = useState('');
  const [exclusion, setExclusion] = useState('');
  const [timeFrame, setTimeframe] = useState('');
  const [userCountry, setUsercountry] = useState([]);
  const [postCountry, setPostcountry] = useState([]);
  const [location, setLocation] = useState('');
  const [locationRadius, setLocationRadius] = useState('');
  const [titleError, setTitleError] = useState('');
  const [keywordError, setKeyWordError] = useState('');
  const [channelError, setChannelError] = useState('');

  const [toggleData, setToggleData] = useState(toggle);

  const [topicPreviewDataLoading, setTopicPreviewDataLoading] = useState(false);

  const { topicPreviewData } = useSelector((state) => state.topicBuilder) || {};

  useEffect(() => {
    // const loadingFlag = JSON.parse(localStorage.getItem('topicPreviewLoading'));
    // if (loadingFlag) {
    //   setTopicPreviewDataLoading(true);
    // } else if (topicPreviewDataLoading && !loadingFlag) {
    //   setTopicPreviewDataLoading(false);
    // }
    if (topicPreviewData != null && topicPreviewData?.data == "Has Failed") {
      dispatch(
        setMessage({
          message:
            'Unable to create a preview. Please attempt your action again.',
          timer: 'off',
          type: 'rejected',
        })
      );
    } else if (topicPreviewData != null && (topicPreviewData?.data != 'No data' || topicPreviewData?.data != "Has Failed")) {

      setTimeout(() => {
        navigate('/topic/view')
      }, 2000);
    }


  }, [topicPreviewData]);

  const navigate = useNavigate();

  // if (topicPreviewData !== null) {
  //   navigate('/topic/view');
  // }

  const dispatch = useDispatch();

  const characterLimit = 50;
  const descLimit = 250;



  const PerameterForAlert = [
    { label: 'User Engagement (Likes & Reshares)', value: 'User Engagement (Likes & Reshares)' },
    { label: 'Mention Frequency', value: 'Mention Frequency' },
    { label: 'Positive Sentiment Shift', value: 'Positive Sentiment Shift' },
    { label: 'Negative Sentiment Shift', value: 'Negative Sentiment Shift' },
  ]

  const ScanFreqValue = [
    { label: 'Hourly', value: 'Hourly' },
    { label: 'Daily', value: 'Daily' },
    { label: 'Monthly', value: 'Monthly' },
    { label: 'Weekly', value: 'Weekly' },
    { label: 'Quaterly', value: 'Quaterly' },
    { label: 'Yearly', value: 'Yearly' },
  ]

  const notifyMethod = [
    { label: "What's up", value: 'whatsup' },
    { label: "Sms", value: 'sms' },

  ]

  /* const handleConnectChannelsToggle = (selected) => {
    if (channel.includes(selected)) {
      let index = channel.indexOf(selected);
      setChannel([...channel.slice(0, index), ...channel.slice(index + 1)]);
    } else {
      channel.push(selected);
      setChannel([...channel]);
    }

    if (selected.length === 0) {
      setChannelError('Select at least one channel');
    } else {
      setChannelError('');
    }
  }; */

  const handleConnectChannelsToggle = (selected) => {
    if (channel === selected) {
      setChannel(null); // Deselect if the same channel is clicked again
      setKeywordType(['Word', 'Phrase', 'Hashtags', 'Mentions'])
    } else {
      setChannel(selected); // Select the new channel

      setKeywordType((prev) => {
        if (selected === 'twitter') {
          // changeKeywordType('Word', index)
          return ['Word', 'Phrase', 'Hashtags', 'Mentions'];
        } else if (selected === 'instagram') {
          // changeKeywordType('Word', index)
          return ['Word', 'Hashtags', 'Mentions'];
        } else if (selected === 'facebook') {
          // changeKeywordType('Word', index)
          return ['Word'];
        } else {
          // changeKeywordType('Word', index)
          return ['Word', 'Phrase', 'Hashtags', 'Mentions'];
        }
      });
    }

    if (!selected) {
      setChannelError('Select at least one channel');
    } else {
      setChannelError('');
    }
  };

  const handleSelectChange = (selectedLanguage) => {
    const langValue = selectedLanguage.map((lan) => lan.value);
    setSelectedLanguage(langValue);
  };

  function exclusionQuery(exclude) {
    const str = exclude();
    setExclusion(str);
  }

  const handleSelectedCountriesChange = (selectedCountries) => {
    const userCountryValues = selectedCountries.map((country) => country.value);
    setUsercountry(userCountryValues);
  };

  const handlePostCountry = (postCountrySelected) => {
    const postCountryValue = postCountrySelected.map(
      (country) => country.value
    );
    setPostcountry(postCountryValue);
  };

  const handleLocationChange = (locationSelected) => {
    setLocation(locationSelected);
  };

  const handleLocationRadiusChange = (radius) => {
    setLocationRadius(radius);
  };

  const handleButtonClick = (event) => {
    event.preventDefault();

    let activeError = false;

    if (inputValue === '') {
      activeError = true;
      setTitleError('Topic Title is required');
    }

    if (queryString.length === '' || queryString === false) {
      activeError = true;
      setKeyWordError('Please specify Keywords');
    } else {
      setKeyWordError('');
    }

    if (channel.length === 0) {
      activeError = true;
      setChannelError('Please select atleast one channel');
    }

    if (activeError) {
      return false;
    }
    const inputData = {
      title: inputValue,
      channels: channel,
      keywords: queryString,
      userCountry: userCountry,
    };

    if (desc) {
      inputData.description = desc;
    }
    if (selectedLanguage) {
      let langVal = [];
      langVal = selectedLanguage.map((lan) => lan.value);
      inputData.languages = langVal;
    }

    if (exclusion) {
      inputData.exclusions = exclusion;
    }

    if (timeFrame) {
      inputData.timeFrame = timeFrame;
    }

    if (userCountry) {
      inputData.userCountry = userCountry;
    }

    if (postCountry) {
      inputData.postCountry = postCountry;
    }

    if (location) {
      inputData.location = location;
      if (locationRadius) {
        inputData.locationRadius = locationRadius;
      }
    }

    if (toggleData[0].isActive) {
      inputData.include_sensitive_content = true;
    }
    if (toggleData[1].isActive) {
      inputData.only_posts_with_media = true;
    }
    if (toggleData[2].isActive) {
      inputData.fetch_reposts = true;
    }
    if (toggleData[3].isActive) {
      inputData.only_posts_from_verified_users = true;
    }


    dispatch(topicPreview(inputData)).then((res) => {
      setTopicPreviewDataLoading(false);
    });
    setTopicPreviewDataLoading(true);
    // if (inputValue && channel && queryString) {
    // }
  };

  // const dataTemp = {
  //   title: 'Iphone 14',
  //   channels: ['Twitter', 'Instagram'],
  //   keywords: '(  aa  )',
  //   userCountry: ['India'],
  //   description: 'some des',
  //   language: ['en', 'es'],
  //   exclusion: "(  '--aa'  )",
  //   timeFrame: 'Last 1 month',
  //   postCountry: ['United States', 'India'],
  //   location: {
  //     label: 'Amsterdam, Netherlands',
  //     value: 'Amsterdam',
  //   },
  //   locationRadius: '1000',
  //   include_sensitive_content: true,
  //   only_posts_with_media: true,
  //   fetch_reposts: true,
  //   only_posts_from_verified_users: true,
  // };

  const handleInputChange = (e) => {
    const newValue = e.target.value;

    if (newValue != '') {
      setTitleError('');
    }
    setInputValue(newValue);
  };
  function returnQueryString(formQuery) {
    let result = formQuery();
    setQueryString(result);
  }

  return (
    <Wrapper>
      {topicPreviewDataLoading && (
        <LoadingWrapper>
          <div>
            <CircularProgress />
          </div>
        </LoadingWrapper>
      )}
      <Text>
        Effortlessly construct custom queries and generate comprehensive
        reports, delivering actionable intelligence at your fingertips
      </Text>
      <QueryBuilderTitle_with_PreviewButton>
        <TopicText>Query Composer</TopicText>
        <Preview onClick={handleButtonClick}>Preview</Preview>
      </QueryBuilderTitle_with_PreviewButton>

      <MainContainer>
        <ColumnWrapper>
          <KeywordWrapper>
            <Label>
              Channels <span>*</span>
            </Label>
            <br />
            <SubLabel>Select interested channels for topic creation</SubLabel>
            <ConnectedChannels
              handleConnectChannelsToggle={handleConnectChannelsToggle}
              channel={channel}
            />
            {channelError && <ErrorMessage>{channelError}</ErrorMessage>}
          </KeywordWrapper>
          <Input
            require={true}
            name='Topic'
            placeholder='Enter topic title'
            label={'Topic Title '}
            customStyle={{ width: '95%' }}
            onChange={handleInputChange}
            characterLimit={characterLimit}
            value={inputValue}
            error={titleError}
            limit={characterLimit}
            maxLength={characterLimit}
          />
          {

            channel === 'facebook' ?
              <></>
              :
              <KeywordWrapper>
                <Label>Language</Label>
                <br />
                <SubLabel>Select multiple languages to monitor topics</SubLabel>
                <MultipleCountriesSelect


                  placeholder='Select preferred language'
                  language={lang ? languages : countries}
                  value={selectedLanguage}
                  setValue={setSelectedLanguage}

                />
              </KeywordWrapper>
          }

          {/*  <KeywordWrapper>
            <Label>Timeframe</Label>
            <br />
            <SubLabel>
              Specify the timeframe for integrating historical data
            </SubLabel>
            <Select
              style={{
                placeholderFontWeight: '400',
                placeholderColor: '#636363',
              }}
              placeholder='Select desired timeframe  '
              value={timeFrame}
              option={timeframe}
              onChange={(selectTimeframe) => {
                setTimeframe(selectTimeframe);
              }}
            />
          </KeywordWrapper> */}
          <KeywordWrapper>
            <Label>
              Keywords <span>*</span>
            </Label>
            <br />
            <SubLabel>
              Customize topic search by including specific keywords
            </SubLabel>
            <QueryString
              keywordType={keywordType}
              placeholder='Enter keywords'
              returnQueryString={returnQueryString}
              channel={channel}
            //keyword = {queryString}
            />
            {keywordError && <ErrorMessage>{keywordError}</ErrorMessage>}
          </KeywordWrapper>
          {

            channel === 'facebook' ?
              <></>
              :
              <KeywordWrapper>
                <Label>Location Origin </Label>
                <br />
                <SubLabel>
                  Specify the sweep radius for geo-spatial filtering
                </SubLabel>

                <LocationOrigin
                  placeholder='Search...'
                  onChange={handleLocationChange}
                  onChangeRadius={handleLocationRadiusChange}
                />
              </KeywordWrapper>
          }


          {/*  <KeywordWrapper>
            <Label>User Country</Label>
            <br />
            <SubLabel>
              Filter content based on user's home country; default is global
            </SubLabel>
            <MultipleCountriesSelect
              placeholder='Select country'
              onChange={handleSelectedCountriesChange}
            />
          </KeywordWrapper> */}
        </ColumnWrapper>
        {
          channel === 'facebook' ?
            <ColumnWrapper>
              <Description
                label={'Description'}
                placeholder='Enter a brief description specifying the topic’s objective...'
                value={desc}
                characterLimit={descLimit}
                onChange={(e) => {
                  setDesc(e.target.value);
                }}
              />
            </ColumnWrapper>
            :
            <ColumnWrapper>
              <Description
                label={'Description'}
                placeholder='Enter a brief description specifying the topic’s objective...'
                value={desc}
                characterLimit={descLimit}
                onChange={(e) => {
                  setDesc(e.target.value);
                }}
              />

              <KeywordWrapper>
                <Label>Exclusions</Label>
                <br />
                <SubLabel>
                  Customize topic search by excluding specific keywords
                </SubLabel>
                <QueryString
                  placeholder='Enter Exclusions'
                  returnQueryString={exclusionQuery}
                  exclude={true}
                //keyword = {queryString}
                />
              </KeywordWrapper>

              <ItemList
                items={toggleData}
                exclusion={false}
                setData={setToggleData}
              />

              {/*  <KeywordWrapper>
              <Label>Post Country</Label>
              <br />
              <SubLabel>
                Filter content based on the country of origin; default is global
              </SubLabel>
              <MultipleCountriesSelect
                placeholder='Select country'
                onChange={handlePostCountry}
              />
            </KeywordWrapper> */}
            </ColumnWrapper>
        }

      </MainContainer>

      {/* <div style={{
        borderTop: '1px solid rgba(162, 162, 162, 1)',

        marginTop: '30px'
      }}>

        <div style={{
          padding: '24px 0px'
        }}>
          <div style={{
            display: 'flex',
            // -webkit-box-align: center;
            alignItems: 'center',
            // -webkit-box-pack: justify;
            justifyContent: 'space-between',

            width: '30%'
          }}>
            <h3 style={{
              margin: '0px',

              fontSize: '18px',
              fontWeight: '700',
              color: 'rgba(53, 60, 247, 1)'
            }}>Alert Configuration</h3>
            <StyledSwitch
              checked={showAlert}
              onClick={() => setShowAlert(!showAlert)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </div>

          <p style={{
            marginTop: '5px',
            marginBottom: '10px'
          }}>Configure notification thresholds for metric spikes</p>
        </div>
      </div>
      <div>
        {
          showAlert &&
          <ShowAlertWrapper >
            <FlexAlertWrapper>
              <div style={{ width: '48%' }}>


                <LabelStyle>Peremeter For Alerting</LabelStyle>
                <br />
                <LabelParaStyle
                >
                  Configure alerts based on the following parameters
                </LabelParaStyle>

                <MultipleCountriesSelect
                  value={perForAlert}
                  setValue={setPerForAlert}
                  placeholder='Select Peremeter For Alerting'
                  language={PerameterForAlert}
                />


              </div>
              <div style={{ width: '48%' }}>

                <LabelStyle>Scan Frequency
                </LabelStyle>

                <br />
                <LabelParaStyle
                  style={{
                    display: 'inline-block',
                    marginTop: "5px",
                    marginBottom: '10px',
                    marginLeft: '8px',
                    color: 'rgb(99, 99, 99)',
                    fontFamily: "Open Sans",
                    fontSize: '15px',
                    fontWeight: '400'
                  }}
                >
                  Define the time interval to scan platform
                </LabelParaStyle>

                <MultipleCountriesSelect
                  value={scanFreq}
                  setValue={setScanFreq}
                  placeholder='Select Scan Frequency'
                  language={ScanFreqValue}
                />
              </div>
            </FlexAlertWrapper>


            <div style={{
              marginTop: '20px'
            }}>


              <LabelStyle>Threshold Configuration
              </LabelStyle>


              <br />
              <LabelParaStyle

              >
                Enter a parameter limit that, once surpassed, will activate an alert
              </LabelParaStyle>


              <input
                placeholder='Enter Parameter'
                style={{
                  boxSizing: 'border-box',
                  padding: '14px 18px',
                  width: '100%',
                  background: 'rgb(255, 255, 255)',
                  border: '1px solid rgb(162, 162, 162)',
                  borderRadius: '15px',
                  outline: 'none',
                  marginTop: '6px',
                  font: 'inherit'
                }} />

            </div>

            <div style={{
              marginTop: '20px'
            }}>

              <LabelStyle>Alert selected users
              </LabelStyle>

              <input
                placeholder='Search Users...'
                style={{
                  boxSizing: 'border-box',
                  padding: '14px 18px',
                  width: '100%',
                  background: 'rgb(255, 255, 255)',
                  border: '1px solid rgb(162, 162, 162)',
                  borderRadius: '15px',
                  outline: 'none',
                  marginTop: '10px',
                  font: 'inherit'
                }}

              />

            </div>

            <div style={{
              marginTop: '20px'
            }}>

              <LabelStyle> Preferred notification method
              </LabelStyle>
              <div style={{ marginTop: '10px', width: '48%' }}>
                <MultipleCountriesSelect
                  value={selectedNotifyMethod}
                  setValue={setSelectedNotifyMethod}
                  placeholder='Select your preferred notification method'
                  language={notifyMethod}
                />
              </div>


            </div>

          </ShowAlertWrapper>
        }
      </div> */}

    </Wrapper>
  );
}

export default TopicBuilder;
