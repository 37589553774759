import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import SearchBar from '../../Common/SearchBar/SearchBar';
import { DeleteIcon, DeleteIconSentinalHub, DownArrowIcon, Downarrow, date_icon, sort_icon } from '../../Common/SvgIcon/CommonSvgIcons';
import MailReport from './MailReport';
import RecentReports from './RecentReports';

import { useDispatch, useSelector } from 'react-redux';
import { allMailDataEmpty, allReportDataEmpty, deleteReport, deleteReportCI, getAllMailList, getAllMailListCI, getReport, getReportCI, hardDeleteReport, hardDeleteReportCI } from '../../store/redux/slices/sentinelHubSlice';
import { Close, Download } from '@mui/icons-material';
import ReportsModal from './ReportsModal';
import SplitScreenReports from './SplitScreenReports';
import Pagination from '../../components/Pagination/Pagination';
import DropDown from './Download/ReportDownload/DropDown/Dropdown';
import { getPersonalDetails } from '../../store/redux/slices/tenantSettingSlice';
import { convertToTimeZone } from '../../utils';
import ReportsModalInsights from './ReportsModalInsights';


const Wrapper = styled.div`
  height: calc(100vh - 60px);
  overflow: hidden;
`;

const SearchWrapper = styled.div`
  gap:30px;
  padding: 10px 0;
  display: flex;
  justify-content: start;
  align-items: center;
  border-bottom: 1px solid rgba(99, 99, 99, 0.25);
  width: 98% !important;
  margin: auto;
  margin-bottom: 10px;

  & .searchInput {
    width: 410px;
  }

  & .dateIcon {
    margin: 0 20px 0 10px;
  }
`;


// grid-template-columns: 20% 22% 1fr;
const MainWrapper = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: 20%  1fr;
  width: 98%;
  margin-left: auto;
  margin-right: auto;
 
`;

const SideBarWrapper = styled.div`
  text-align: left;
  font-family: Open Sans;
  font-weight: 600;

  padding: 15px 10px;

  background: #f4f4f4;
`;

// border-right: 1px solid rgba(162, 162, 162, 0.35);
const Title = styled.div`
  padding-left: 10px;
  margin-bottom: 15px;
`;

const Header = styled.div`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid rgba(99, 99, 99, 0.2);
  padding-bottom: 10px;
  padding-left: 10px;
`;

const RecentSort = styled.div`
  font-size: 13px;
  font-weight: 400;

  border-radius: 8px;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 0px 8px 0px rgba(162, 162, 162, 0.24);
  padding: 5px 8px;

  display: flex;
  align-items: center;
  gap: 8px;

  cursor: pointer;
`;

const SideBar = styled.div`
  border-radius: 10px;
  background: rgba(174, 177, 252, 0.16);
  padding: 15px 8px;
  & > div {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    padding: 12px 20px;
    margin-top: 5px;

    & span {
      font-size: 12px;
      font-weight: 400;
    }

    &:hover {
      font-weight: 600;
      border-radius: 10px;
      background: #353cf7;
      color: #fff;
    }
  }

  & .activeTab {
    font-weight: 600;
    border-radius: 10px;
    background: #353cf7;
    color: #fff;
  }
`;

const Recent = styled.div`
  padding: 10px;
  padding-right: 0px;
  border-right: 1px solid rgba(162, 162, 162, 0.35);
  margin-right: 12px;
  font-family: Open Sans;
  text-align: left;
  font-size: 12px;
  font-weight: 400;

  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

// const DeleteIconWrapper = styled.p`
//   margin:0px;


//   &:hover .delete-icon {
//     visibility: visible;
//   }
// `;


const TableRow = styled.tr`
  &:hover .delete_icon {
    visibility: visible;
  }
`;

const DeleteIconWrapper = styled.p`
  visibility: hidden;
  margin: 0px;

  ${TableRow}:hover & {
    visibility: visible;
  }
`;



// const RowData = styled.tr`
// border-top: 1px solid rgb(210, 210, 210);
// border-bottom: 1px solid rgb(210, 210, 210);
// -webkit-box-pack: justify;
// justify-content: space-between;
// width: 100%;
// gap: 5px;




// `;
// display: flex;

// const FilterColumn = styled.th`
// color: rgb(0, 0, 0);
// font-size: 18px;
// font-weight: 400;
// cursor: pointer;
// width: 100%;
// -webkit-box-pack: center;
// justify-content: center;
// padding: 10px 0px;
// `;



const FlexWrapper = styled.div`
  
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
gap:7px;
`;

const DropDownWrapper = styled.div`
  background: white;
  position: absolute;
  top:95%;
  box-shadow: 4px 3px 7px 2px #989898;
  padding: 11px;
  text-align:left;
  // margin-top: 2vh;
  min-width: 125px;
  // top: 103%;
  // transform: screenId == 2 ? 'translateX(50%)' : 'translateX(5%)', // Center horizontally
   // top: '103%';

`;

const DropDownContentDiv = styled.div`
    padding: 7px;
    font-size: 18px;
    display: flex;
    align-items: center
`;

const InputDropDownCheckBox = styled.input`
  height: 16px;
  margin: 0px;
  margin-right: 10px;
  width: 16px;
`;
const EllipsisText = styled.div`
  width: 280px;          /* Fixed width */
  white-space: nowrap;    /* Prevent text from wrapping */
  overflow: hidden;       /* Hide overflowed content */
  text-overflow: ellipsis; /* Show ellipsis when the text overflows */
  // border: 1px solid #ccc; /* Optional: just to visualize the fixed width */
  padding: 5px;           /* Optional: padding for better appearance */
`;

const TableAction = styled.td`
  padding: 8px;
  border-bottom: 1px solid #ddd;

`;

const TableActionWrapper = styled.td`
  padding: 8px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
`;

const ParaWithMargin = styled.p`
// margin: 0px;

`;


const SentinelHub = ({ showci }) => {

  const [inputValue, setInputValue] = useState('');
  const [filteredArray, setFilteredArray] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [allTopicChatterList, setAllTopicChatterList] = useState([]); // useState for managing the mail list

  const filterCol = [1, 2, 3, 4]
  const [activeTab, setActiveTab] = useState(1);
  const [selectedReport, setSelectedReport] = useState();
  const [selectedTab, setSelectedTab] = useState([]);
  const [splitData, setSplitData] = useState();
  const [screenId, setScreenId] = useState(-1);
  const [labelValue, setLabelValue] = useState('Inbox');
  const [filterValue, setFilterValue] = useState([
    {
      label: 'From',
      id: 'user_name',
      sort: { asc: false, desc: false },
      doSort: false
    },

    {
      label: 'Channel',
      id: 'channel',
      sort: { amazon: false, twitter: false, flipkart: false, instagram: false },
      doSort: false
    },
    {
      label: 'Date and Time',
      id: 'creation_time',
      sort: { asc: false, desc: false },
      doSort: false
    },
    {
      label: 'Title',
      id: 'title_name',
      sort: { asc: false, desc: false },
      doSort: false
    },

  ]);

  let currentFilters = {
    from: { asc: false, desc: false },
    created_at: { asc: false, desc: false },
    channel: { amazon: false, twitter: false, flipkart: false, instagram: false },
    title: { asc: false, desc: false }
  };

  const dropDownOptions = [
    {
      label: 'Arrange By',
      options: [
        { value: 'creation_time', label: 'Date and Time', sec: 1 },
        { value: 'user_name', label: 'From', sec: 1 },
        { value: 'channel', label: 'Channel', sec: 1 },
        { value: 'title_name', label: 'Title', sec: 1 }
      ]
    },
    {
      label: 'Sort',
      options: [
        { value: 'asc', label: 'Newest on top', sec: 2 },
        { value: 'desc', label: 'Oldest on top', sec: 2 }
      ]
    }
  ];


  const PageSize = 7;
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  const [showSplitScreen, setShowSplitScreen] = useState(false)
  const [openPopup, setOpenPopup] = useState(false)

  const { getAllMailListData } =
    useSelector((state) => state.sentinelHub) || [];


  const { personalDetailsData } = useSelector((state) => state.tenantSetting) || {};


  const formatValue = personalDetailsData?.app_preference?.date_format === "dd/mm/yyyy" ? 'dd-MM-yyyy' :
    personalDetailsData?.app_preference?.date_format === "mm/dd/yyyy" ? 'MM-dd-yyyy' :
      personalDetailsData?.app_preference?.date_format === "yyyy/mm/dd" ? 'yyyy-MM-dd' :
        'dd-MM-yyyy';


  useEffect(() => {
    if (personalDetailsData === null) {
      dispatch(getPersonalDetails());
    }

    const fetchMailList = async () => {
      try {
        const action = showci ? getAllMailListCI() : getAllMailList();
        await dispatch(action);
      } catch (err) {
        console.log(err);
      }
    };

    fetchMailList();
  }, [showci]);

  useEffect(() => {
    if (getAllMailListData?.mail_list) {
      setAllTopicChatterList(getAllMailListData.mail_list);
    }
  }, [getAllMailListData]);


  // console.log(allTopicChatterList, "allTopicChatterList");
  let inbox;
  let shared;
  let deleted;

  if (allTopicChatterList.length > 0) {
    let mailData = [...allTopicChatterList]
    let recentVal = mailData?.sort((a, b) => {
      // Convert ISO 8601 timestamps to milliseconds since epoch for comparison
      let timeA = iso8601ToMillis(a?.creation_time);
      let timeB = iso8601ToMillis(b?.creation_time);

      // Sort in descending order
      return timeB - timeA;
    });
    inbox = recentVal.filter((el) => el.deleted_id === 0);
    deleted = recentVal.filter((el) => el.deleted_id === 1);

    const brandUser = JSON.parse(localStorage.getItem('brandUser'));
    shared = recentVal.filter(
      (el) =>
        el.shared_user_id === brandUser?.user_details.user_id &&
        el.deleted_id === 0
    );
  } else {
    inbox = [];
    shared = [];
    deleted = []
  }

  useEffect(() => {
    if (allTopicChatterList.length > 0) {
      setSelectedTab([...inbox]);
    }
  }, [allTopicChatterList]);


  const handleSelectChange = (selectedOption) => {
    const tempReviews = [...selectedTab];


    // Filter out options from both sections
    const sec1Options = selectedOption.filter(option => option.sec === 1);
    const sec2Options = selectedOption.filter(option => option.sec === 2);
    // Update selected options based on the section
    setSelectedOption([...sec1Options.slice(-1), ...sec2Options.slice(-1)]);
    if (sec2Options?.length > 0) {
      if (sec1Options?.length > 0 && sec2Options[0].value == 'desc') {

        tempReviews.sort((a, b) => {
          // Access the values for sorting from objects a and b
          const valueA = a[sec1Options[0]?.value]?.replace(/{|}/g, '').toLowerCase() || '';
          const valueB = b[sec1Options[0]?.value]?.replace(/{|}/g, '').toLowerCase() || '';

          // Compare the values and return -1, 0, or 1 for sorting
          if (valueA > valueB) {
            return -1;
          }
          if (valueA < valueB) {
            return 1;
          }
          return 0;
        });



        setSelectedTab(tempReviews)
      } else {
        if (sec1Options?.length > 0) {

          tempReviews.sort((a, b) => {
            // Access the values for sorting from objects a and b
            const valueA = a[sec1Options[0]?.value]?.replace(/{|}/g, '').toLowerCase() || '';
            const valueB = b[sec1Options[0]?.value]?.replace(/{|}/g, '').toLowerCase() || '';

            // Compare the values and return -1, 0, or 1 for sorting
            if (valueA < valueB) {
              return -1;
            }
            if (valueA > valueB) {
              return 1;
            }
            return 0;
          });


          setSelectedTab(tempReviews)

        }


      }

    } else {
      if (sec1Options?.length > 0) {

        tempReviews.sort((a, b) => {
          // Access the values for sorting from objects a and b
          const valueA = a[sec1Options[0]?.value]?.replace(/{|}/g, '').toLowerCase() || '';
          const valueB = b[sec1Options[0]?.value]?.replace(/{|}/g, '').toLowerCase() || '';

          // Compare the values and return -1, 0, or 1 for sorting
          if (valueA < valueB) {
            return -1;
          }
          if (valueA > valueB) {
            return 1;
          }
          return 0;
        });


        setSelectedTab(tempReviews)
      }
    }

    if (selectedOption.length < 1) {
      setSelectedTab(allTopicChatterList.filter((el) => el.deleted_id === 0))
    }


  };


  const sideBarData = [
    { id: 1, label: 'Inbox', value: inbox?.length },
    { id: 2, label: 'Shared', value: shared?.length },
    { id: 3, label: 'Deleted', value: deleted?.length },
  ];

  const handelTabClick = (id, label) => {
    setActiveTab(id);
    setLabelValue(label)
    if (label === 'Inbox') {
      setSelectedTab([...inbox]);
    } else if (label === 'Shared') {
      setSelectedTab([...shared]);
    } else if (label === 'Deleted') {
      setSelectedTab([...deleted]);
    }
  };


  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero based
    const year = date.getFullYear();

    // Pad single digit day/month with leading zero
    const formattedDay = String(day).padStart(2, '0');
    const formattedMonth = String(month).padStart(2, '0');

    return `${formattedDay}/${formattedMonth}/${year}`;
  }


  let { getAllReportData } = useSelector((state) => state.sentinelHub) || [];

  // console.log(getAllReportData, "getAllReportData");
  function formatDateTimeToTime(dateTimeString) {
    const dateTime = new Date(dateTimeString);
    const hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert hours from 24-hour format to 12-hour format
    const formattedHours = hours % 12 || 12; // 0 becomes 12

    // Pad single digit hours/minutes with leading zero
    const formattedHoursStr = String(formattedHours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');

    // Construct formatted time string
    const formattedTime = `${formattedHoursStr}:${formattedMinutes} ${ampm}`;

    return formattedTime;
  }


  const handleOpenSplit = (data) => {
    dispatch(allReportDataEmpty())
    if (showci) {

      dispatch(getReportCI(data?.competitive_intelligence_outlook_id));
    } else {

      dispatch(getReport(data?.email_id));
    }
    setSplitData(data)
    setShowSplitScreen(true)
  }


  const handleClose = () => {
    setOpenPopup(false)
  }

  const handleOpenPopup = (data) => {
    dispatch(getReport(data?.email_id));
    setSelectedReport(data);
    setOpenPopup(true)
  }

  function iso8601ToMillis(iso8601) {
    return Date.parse(iso8601);
  }



  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;

    return selectedTab.slice(firstPageIndex, lastPageIndex);

  }, [currentPage, selectedTab]);



  const handleSetId = (e) => {
    setScreenId(e)
  }
  const handleCloseId = () => {
    // setTimeout(function() {
    setScreenId(-1)
    // }, 3000);

  }

  const ascFlt = (filteredData, colid) => {

    return filteredData.sort((a, b) => {
      // Access the values for sorting from objects a and b
      const valueA = a[colid]?.replace(/{|}/g, '').toLowerCase() || '';
      const valueB = b[colid]?.replace(/{|}/g, '').toLowerCase() || '';

      // Compare the values and return -1, 0, or 1 for sorting
      if (valueA < valueB) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }
      return 0;
    });
  }

  const descFlt = (filteredData, colid) => {

    return filteredData.sort((a, b) => {
      const valueA = a[colid]?.replace(/{|}/g, '').toLowerCase() || '';
      const valueB = b[colid]?.replace(/{|}/g, '').toLowerCase() || '';

      // Compare the values and return -1, 0, or 1 for sorting
      if (valueA > valueB) {
        return -1;
      }
      if (valueA < valueB) {
        return 1;
      }
      return 0;
    });
  }


  const handleChangeFilterFunc = (id, type, check, column) => {


    let filteredData = [...selectedTab]
    let updatedFilters = [...filterValue];

    if (type === 'asc' || type === 'desc') {

      updatedFilters = updateSortValues(false)
      if (type === 'asc') {
        updatedFilters[id].sort['asc'] = true;
        updatedFilters[id].sort['desc'] = false;
        filteredData = ascFlt(filteredData, filterValue[id]?.id)
      } else {
        updatedFilters[id].sort['desc'] = true;
        updatedFilters[id].sort['asc'] = false;
        filteredData = descFlt(filteredData, filterValue[id]?.id)
      }

    } else {

      updatedFilters[id].sort[type] = check;
      filteredData = filterChannelData(filteredData)
    }

    setSelectedTab([...filteredData])
    setFilterValue(updatedFilters)









  }


  const filterChannelData = (data) => {
    let amazonFilter = [];
    let flipkartFilter = [];
    let twitterFilter = [];
    let instagramFilter = [];
    let dataValue = []

    if (labelValue === 'Inbox') {
      dataValue = [...inbox];
    } else if (labelValue === 'Shared') {
      dataValue = [...shared];
    } else if (labelValue === 'Deleted') {
      dataValue = [...deleted];
    }

    if (filterValue[1].sort.amazon == true) {
      amazonFilter = dataValue?.filter((i) => i.channel == 'amazon')
    }
    if (filterValue[1].sort.flipkart == true) {
      flipkartFilter = dataValue?.filter((i) => i.channel == 'flipkart')
    }
    if (filterValue[1].sort.twitter == true) {
      twitterFilter = dataValue?.filter((i) => i.channel == 'twitter')
    }
    if (filterValue[1].sort.instagram == true) {
      instagramFilter = dataValue?.filter((i) => i.channel == 'instagram')
    }
    if (filterValue[1].sort.amazon == false && filterValue[1].sort.flipkart == false && filterValue[1].sort.twitter == false && filterValue[1].sort.instagram == false) {
      if (labelValue === 'Inbox') {
        return [...inbox];
      } else if (labelValue === 'Shared') {
        return [...shared];
      } else if (labelValue === 'Deleted') {
        return [...deleted];
      }
    }
    return [
      ...amazonFilter,
      ...flipkartFilter,
      ...twitterFilter,
      ...instagramFilter
    ]

  }




  const handleInputChange = (event) => {
    const value = event?.target?.value.toLowerCase();
    setInputValue(value);

    let searchData = [];

    if (event?.target?.value) {

      if (labelValue === 'Inbox') {
        searchData = [...inbox];
      } else if (labelValue === 'Shared') {
        searchData = [...shared];
      } else if (labelValue === 'Deleted') {
        searchData = [...deleted];
      }


      let updatedFilters = updateSortValues(true);

      setFilterValue(updatedFilters);

      const filtered = searchData.filter(item => {

        // filter based on other fields
        return (
          item.channel.toLowerCase().includes(value) ||
          item.creation_time.toLowerCase().includes(value) ||
          item.title_name.toLowerCase().includes(value) ||
          item.user_name.toLowerCase().includes(value)
        );
      });


      setSelectedTab(filtered);
    } else {
      if (labelValue === 'Inbox') {
        setSelectedTab([...inbox]);
      } else if (labelValue === 'Shared') {
        setSelectedTab([...shared]);
      } else if (labelValue === 'Deleted') {
        setSelectedTab([...deleted]);
      }
    }






  };

  const deleteFromIndex = (data) => {

    // console.log(data, "data");
    if (showci) {
      if (data?.deleted_id == 1) {
        dispatch(hardDeleteReportCI(data?.competitive_intelligence_outlook_id))
      } else {
        dispatch(deleteReportCI(data?.competitive_intelligence_outlook_id))
      }

      dispatch(allMailDataEmpty())
        .then(() => {

          dispatch(getAllMailListCI())
        })
    } else {
      if (data?.deleted_id == 1) {
        dispatch(hardDeleteReport(data?.email_id))
      } else {
        dispatch(deleteReport(data?.email_id))
      }
      dispatch(allMailDataEmpty())
        .then(() => {

          dispatch(getAllMailList())
        })
    }


    // setAllTopicChatterList(getAllMailListData.mail_list);
  }


  const updateSortValues = (val) => {
    // Update the state immutably using map
    if (val) {

      let mapData = filterValue.map(item => ({

        ...item,
        sort: Object.fromEntries(
          Object.entries(item.sort).map(([key, value]) => [key, false])
        )
      })
      )

      return mapData;
    } else {
      let mapData = filterValue.map(item =>
        item?.id !== 'channel' ?
          ({

            ...item,
            sort: Object.fromEntries(
              Object.entries(item.sort).map(([key, value]) => [key, false])
            )
          })
          :
          ({ ...item })
      );


      return mapData
    }



  };


  return (
    <Wrapper>
      <SearchWrapper>
        {/* <div className='dateIcon'>{date_icon}</div> */}
        <div className='searchInput'>
          <SearchBar
            value={inputValue}
            onChange={handleInputChange}
            placeholder='Search reports' height='40px' />
        </div>
        <div>
          <DropDown
            options={dropDownOptions}
            selectedTab={selectedTab}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            setSelectedTab={setSelectedTab}
            handleSelectChange={handleSelectChange}
          />

        </div>
      </SearchWrapper>
      <MainWrapper>
        <SideBarWrapper>
          <Title>Reports</Title>
          <SideBar>
            {sideBarData.map((el) => (
              <div
                key={el.id}
                className={el.id === activeTab ? 'activeTab' : ''}
                onClick={() => handelTabClick(el.id, el.label)}
              >
                {el.label} <span>{el.value}</span>
              </div>
            ))}
          </SideBar>
        </SideBarWrapper>

        <div style={{
          width: '99%',
          marginLeft: " auto",
          // marginRight: 'auto',
          overflowX: 'auto',
          position: 'relative',
          zIndex: '999'
        }}>
          <div style={{
            height: showSplitScreen ? `calc(100vh - 450px)` : `calc(100vh - 200px)`,
            overflowX: 'auto',
            marginBottom: '15px'
          }}>
            <table style={{
              width: '100%',
              borderCollapse: 'collapse'
            }}>
              <thead style={{
                height: '6.7vh',
                background: "#bfc0e9",
                position: 'sticky',
                top: 0,
                zIndex: 1
              }}>


                <tr className='header'>
                  {filterValue.map((column, i) =>
                    <th
                      key={i}

                      onMouseOver={(e) => handleSetId(i)}
                      onMouseLeave={(e) => handleCloseId(i)}
                    >
                      <FlexWrapper
                        onClick={(e) => handleSetId(i)}
                        onDoubleClick={(e) => handleCloseId(i)}
                      >
                        <p style={{ margin: '0' }}>{column.label}&nbsp;</p>
                        {sort_icon}
                      </FlexWrapper>

                      {screenId == i && (
                        <div style={{
                          display: 'flex',
                          justifyContent: 'center'
                          // left: '55%'
                        }}>
                          {
                            screenId == 0 ?
                              <DropDownWrapper style={{ position: 'absolute' }}>

                                <DropDownContentDiv><InputDropDownCheckBox type='radio' checked={column.sort.asc} onChange={(e) => handleChangeFilterFunc(0, "asc", e.target.checked, column.sort.asc)} /> A - Z </DropDownContentDiv>
                                <DropDownContentDiv><InputDropDownCheckBox type='radio' checked={column.sort.desc} onChange={(e) => handleChangeFilterFunc(0, 'desc', e.target.checked)} /> Z - A</DropDownContentDiv>

                              </DropDownWrapper>
                              : screenId == 1 ?
                                <DropDownWrapper style={{ position: 'absolute' }}>
                                  <DropDownContentDiv ><InputDropDownCheckBox type='checkbox' checked={column.sort.twitter} onChange={(e) => handleChangeFilterFunc(1, "twitter", e.target.checked, column)} />Twitter</DropDownContentDiv>
                                  <DropDownContentDiv><InputDropDownCheckBox type='checkbox' checked={column.sort.amazon} onChange={(e) => handleChangeFilterFunc(1, "amazon", e.target.checked)} />Amazon</DropDownContentDiv>

                                  <DropDownContentDiv><InputDropDownCheckBox type='checkbox' checked={column.sort.instagram} onChange={(e) => handleChangeFilterFunc(1, "instagram", e.target.checked)} />Instagram</DropDownContentDiv>
                                  <DropDownContentDiv><InputDropDownCheckBox type='checkbox' checked={column.sort.flipkart} onChange={(e) => handleChangeFilterFunc(1, "flipkart", e.target.checked)} />Flipkart</DropDownContentDiv>
                                  {/* <DropDownContentDiv><InputDropDownCheckBox type='radio' style={{ height: '14px',marginRight:'10px' }} /> Z - A</div> */}

                                </DropDownWrapper>
                                : screenId == 2 ?
                                  <DropDownWrapper style={{ position: 'absolute' }}>

                                    <DropDownContentDiv><InputDropDownCheckBox type='radio' checked={column.sort.asc} onChange={(e) => handleChangeFilterFunc(2, "asc", e.target.checked)} /> Newest on Top</DropDownContentDiv>
                                    <DropDownContentDiv><InputDropDownCheckBox type='radio' checked={column.sort.desc} onChange={(e) => handleChangeFilterFunc(2, 'desc', e.target.checked)} /> Oldest on Top</DropDownContentDiv>

                                  </DropDownWrapper>
                                  :
                                  <DropDownWrapper style={{ position: 'absolute' }}>

                                    <DropDownContentDiv><InputDropDownCheckBox type='radio' checked={column.sort.asc} onChange={(e) => handleChangeFilterFunc(3, "asc", e.target.checked)} /> A - Z</DropDownContentDiv>
                                    <DropDownContentDiv><InputDropDownCheckBox type='radio' checked={column.sort.desc} onChange={(e) => handleChangeFilterFunc(3, 'desc', e.target.checked)} /> Z - A</DropDownContentDiv>

                                  </DropDownWrapper>
                          }

                        </div>
                      )}


                    </th>

                    // :
                    // <th key={i}   >{column.label}</th>
                  )}
                </tr>
              </thead>



              <tbody style={{ display: 'table-row-group', overflowY: 'auto' }}>
                {currentTableData?.length > 0 && currentTableData?.map((row, i) => {
                  let { formattedDate, formattedTime } = convertToTimeZone(row?.creation_time, personalDetailsData?.app_preference?.time_zone, personalDetailsData?.app_preference?.time_format === '12 Hour' ? true : false, personalDetailsData?.app_preference?.date_format)
                  // console.log(formattedDate, formattedTime, row?.creation_time, "row?.creation_time");
                  return (
                    <TableRow
                      key={i}
                      style={{ height: '55px' }}
                    >
                      <TableAction

                        onClick={() => handleOpenSplit(row)}
                        onDoubleClick={() => handleOpenPopup(row)}
                      >{row.user_name}</TableAction>
                      <TableActionWrapper >
                        <ParaWithMargin
                          onClick={() => handleOpenSplit(row)}
                          onDoubleClick={() => handleOpenPopup(row)}
                        >{row?.channel ? row?.channel?.replace(/{|}/g, '') : 'Twitter'}
                        </ParaWithMargin>
                        {
                          labelValue == 'Shared' || labelValue == 'Deleted' ?
                            <DeleteIconWrapper
                              className='delete_icon'
                              onClick={() => deleteFromIndex(row)}>{DeleteIconSentinalHub}
                            </DeleteIconWrapper>
                            :
                            ''
                        }

                      </TableActionWrapper>
                      <TableAction

                        onClick={() => handleOpenSplit(row)}
                        onDoubleClick={() => handleOpenPopup(row)}
                      >
                        {formattedDate && formattedDate}{' '}
                        {formattedTime && formattedTime}
                        {/* {formatDate(row.creation_time)}{' '}
                      {formatDateTimeToTime(row.creation_time)} */}
                      </TableAction>
                      <TableAction
                        style={{ width: '280px' }}
                        onClick={() => handleOpenSplit(row)}
                        onDoubleClick={() => handleOpenPopup(row)}
                      >
                        <EllipsisText>{row.title_name}</EllipsisText>
                      </TableAction>
                      {/* <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }} onClick={() => deleteFromIndex(row)}>DE</td> */}
                    </TableRow>
                  )
                }
                )}
              </tbody>
            </table>
            <Pagination
              currentPage={currentPage}
              totalCount={selectedTab.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>



          {
            showSplitScreen && getAllReportData?.length > 0 ?
              <SplitScreenReports
                showci={showci}
                data={splitData}
                getAllReportData={getAllReportData}
                personalDetailsData={personalDetailsData}
                formatValue={formatValue}
                startDate={showci ? splitData?.creation_time : getAllReportData?.[0]?.report?.start_date}
                setShowSplitScreen={setShowSplitScreen} />
              :
              <></>
          }


        </div>

      </MainWrapper>
      {

        openPopup &&
        (
          showci ?
            <ReportsModalInsights
              splitData={splitData}

              open={showSplitScreen} handleClose={handleClose}
            />
            :

            <ReportsModal
              formatValue={formatValue} data={selectedReport} open={showSplitScreen} handleClose={handleClose} />
        )
      }
    </Wrapper>
  );
};

export default SentinelHub;
