import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import SmallSelect from '../Select/SmallSelect';
import { HelpIcon, QuestionIcon } from '../SvgIcon/CommonSvgIcons';
import { format } from 'date-fns';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px 15px;
  box-sizing: border-box;
`;

const Title = styled.div`
  ${'' /* color: #636363; */}
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MainContainer = styled.div`
  flex: 1;
  display: flex;
  width: ${(props) => props.graphWidth};
  margin: ${(props) => props.margin};
  ${'' /* justify-content: center; */}
  align-items: center;
`;

const Span = styled.span`
  color: rgba(0, 0, 0, 0.87);
  font-family: Open Sans;
  font-size: 11px;
  font-weight: 400;
  align-self: flex-end;
  margin-right: 10px;
  margin-bottom: -4px;
`;

const InfoWrapper = styled.div`
  /* display: none; */
  color: #636363;
    position: relative;
    // left: -70px;
    // top: 100%;
    min-width: 200px;
    max-width: 300px;
    min-height: 30px;
    background: #d0ccff;
    padding: 10px;
    border-radius: 8px;
    z-index: 100;
    font-family: Open Sans;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;


    display: flex;
    align-items: center;




  box-shadow: 0px 4px 4px 0px #00000040;

  &:before {

    
    content: "";
    position: absolute;
    top: 15px;
    width: 0px;
    height: 0px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 15px solid rgb(208, 204, 255);
    z-index: 11;
        left: -3%;
    transform: rotate(0deg);
  }
`;



const InfoWrapperDiv = styled.div`
display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`;

const ParagraphTitle = styled.p`
margin:0px;
display: flex;
    align-items: center;
    justify-content: center;
    gap:5px;
    min-height:50px;
`

const GraphHOC = ({
  title = '',
  renderScreen = () => { },
  options = [],
  graphWidth = '100%',
  margin = '0px',
  startDate = '',
  endDate = '',
  option,
  alignLeft,
  hideDropdown,
  formatValue="dd-yyyy-MM",
  showDate=true
}) => {
  const [selectedGraph, setSelectedGraph] = useState(options[0]);

  const [infoText, setInfotext] = useState(false);
  useEffect(() => {
    if (option) {
      setSelectedGraph(option);
    } else {
      setSelectedGraph(options[0]);
    }
  }, [option]);
  const handleSelectedGraph = (opt) => {
    setSelectedGraph(opt);
  };

  return (
    <Wrapper>
      <Title>
        <ParagraphTitle>{title}  {title === 'Most Authoritative' ?
          <InfoWrapperDiv onMouseOver={() => setInfotext(true)} onMouseLeave={() => setInfotext(false)}>
            {HelpIcon}
            {infoText && (
              <InfoWrapper>
                Authoritative Score = Share + Likes + Comments + Views
              </InfoWrapper>
            )}
          </InfoWrapperDiv>
          :
          ''

        }</ParagraphTitle>
        <div style={{ display: hideDropdown === true ? 'none' : 'flex' }}>
          <SmallSelect
            onChange={handleSelectedGraph}
            options={options}
            option={option || options[0]}
            hideDropdown={hideDropdown}
          />
        </div>
      </Title>
      <MainContainer graphWidth={graphWidth} margin={ option == 'Pie Chart' || selectedGraph == 'Pie Chart' ? '0px' : margin}>
        {renderScreen[selectedGraph]}
      </MainContainer>
      { showDate && startDate && (
        <Span>
          This data is observed from {startDate} to {endDate }
        </Span>
      )}
    </Wrapper>
  );
};

export default GraphHOC;
