import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  CrossIcon,
  HorizontalArrowIcon,
  QuestionIcon,
} from '../../../../../Common/SvgIcon/CommonSvgIcons';
import {
  CustomAnalysis,
  CustomAnalysisResponse,
} from '../../../../../store/redux/slices/chatterSlice';
import { chatterList } from '../../../../../store/redux/slices/chatterSlice';
import Lottie from 'lottie-react';
import Loader from '../../../../../assests/animation/Loader2.json';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: max-height;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

   margin: 40px 0px 0px 0px;
  position: relative;

 /*  span {
    position: relative;
    right: 30px;
    top: 15px;
    cursor: pointer;
  } */

  & > div {
   position: relative;
   display: flex;
    cursor: pointer;
    margin-top: 10px;
    margin-left: 10px
  }
`;

const Input = styled.input`
  height: 50px;
  width: 100%;
  padding-left: 15px;

  border-radius: 15px;
  border: 1px solid #a2a2a2;

  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const Image = styled.img`
  width: 100%; // Adjusted to 100% width
  max-width: 420px; // You can adjust the maximum width as needed
  margin: 40px 0px 10px 0px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: auto;
  margin-top: 30px;
`;

const Table = styled.table`
  width: 90%;
  border-collapse: collapse;
  border: 1px solid #ddd;
  text-align: center;
`;

const TableRow = styled.tr`
  border: 1px solid #ddd;
  height: auto;
  margin: 20px;
`;

const TableCell = styled.td`
  padding: 8px; /* Cell padding */
  border-right: 1px solid #ddd;
`;

const TableHead = styled.th`
  height: 50px;
  padding: 10px;
  background: #ededed;
`;

const ContentWrapper = styled.div`
  width: 75%;
`;

const DraftWrapper = styled.ul`
  width: 89%;
  /* padding-left: 2px; */
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  height: auto;
  border: 1px solid #a2a2a2;
  border-radius: 0px 0px 10px 10px;
`;

const DraftItem = styled.li`
  list-style-type: none;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor:pointer;
  /*  background-color: #fff; */
  &:last-child {
    border-bottom: none;
  }

  color: #000;

  font-family: 'Open Sans';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
`;

const Icon = styled.span`
    position: absolute;
    right: 100px;
    top: 50%;
    cursor: pointer;
    transform: translate(0%, -50%);
`;

const ArrowIcon = styled.span`
    position: absolute;
    right: 60px;
    top: 50%;
    cursor: pointer;
    transform: translate(0%, -50%);
`;


const InfoWrapper = styled.div`
  /* display: none; */
  color: #636363;
  position: absolute;
  left: -70px;
  top: 100%;
  min-width: 200px;
  max-width: 300px;
  min-height: 30px;
  background: #d0ccff;
  padding: 10px;
  border-radius: 8px;
  z-index: 100;
  font-family: Open Sans;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
  box-shadow: 0px 4px 4px 0px #00000040;

  &:before { 
    content: "";
    position: absolute;
    top: -15px;
    width: 0px;
    height: 0px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 15px solid rgb(208, 204, 255);
    z-index: 11;
    left: 35%;
    transform: rotate(90deg);
  }
`;

const CustomAnalysisTab = ({
  job_id,
  preview_uuid,
  onUpdateSearchQuery,
  channel,
  onUpdateImage,
  onUpdateTable,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [imageUrl, setImageUrl] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [showDraft, setshowDraft] = useState([]);
  const [selectedDraft,setSelectedDraft] = useState('');
  const [infoText, setInfotext] = useState(false);

  const dispatch = useDispatch();
  const { chatterListDate } =
    useSelector((state) => state.chatterTracker) || [];
  const loading = useSelector((state) => state.chatterTracker.isImageLoading);

  useEffect(() => {
    if (chatterListDate === null) {
      dispatch(chatterList());
    }
  }, []);

  /*  useEffect(() => {
    const storedSearchQuery = localStorage.getItem('searchQuery');
    const storedImage = localStorage.getItem('imageResponse');
    const storedTableData = localStorage.getItem('tableData');

    setSearchQuery(storedSearchQuery || '');
    setImageUrl(storedImage || '');
    setTableData(storedTableData ? JSON.parse(storedTableData) : []);
  }, []);

   */

  const handleSearch = async () => {
    const channelName = channel;
    const uuid = preview_uuid;
    const jobId = job_id ? job_id.toString() : null;
    /*  localStorage.setItem('searchQuery', searchQuery); */
    /*  if (imageUrl !== '' && !tableData) {
      localStorage.setItem('imageResponse', imageUrl);
      localStorage.setItem('tableData', tableData);
    } */
    onUpdateSearchQuery(searchQuery);
    try {
      const data =
      jobId !== undefined && jobId !== null && jobId
          ? await dispatch(CustomAnalysis({ searchQuery, channelName, jobId }))
          : await dispatch(CustomAnalysis({ searchQuery, channelName, uuid }));
      setshowDraft(data?.payload?.response);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleDraftClick = async (suggestion,is_table) => {
    const uuid = preview_uuid;
    const jobId = job_id ? job_id.toString() : null;
    setImageUrl(null);
    setTableData(null);
    setshowDraft([]);
    try {
      // const data = 
      // await dispatch(
      //   CustomAnalysisResponse({
      //     is_table:is_table,
      //     searchQuery: suggestion,
      //     channelName: channel,
      //     jobId: job_id.toString(),
      //   })

        
      const data = 
        jobId !== undefined && jobId !== null && jobId
          ? await dispatch(
            CustomAnalysisResponse({
              is_table:is_table,
              searchQuery: suggestion,
              channelName: channel,
              jobId,
            })
          )
          : await dispatch(
            CustomAnalysisResponse({
              is_table:is_table,
              searchQuery: suggestion,
              channelName:channel,
              uuid,
            })
          );
          localStorage.setItem(`local_${jobId !== undefined && jobId !== null && jobId ? jobId : uuid ? uuid : ''}`, JSON.stringify({
            query:suggestion,
            is_table:is_table,
            response:data?.payload?.response
          }) )
        
      // );
      if (data?.payload?.response?.type === 'image') {
        const imageUrl = data.payload.response.image_url;
        setImageUrl(imageUrl);
        /* localStorage.setItem('imageResponse_chatter', imageUrl); */
      } else if (data?.payload?.response?.type === 'table') {
        const tableData = data.payload.response.data;
        setTableData(tableData);
        /*  localStorage.setItem('tableData_chatter', JSON.stringify(tableData)); */
      }
      setSelectedDraft(suggestion)
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const renderResult = () => {
    if (imageUrl) {
      return <Image src={imageUrl} />;
    } else if (Array.isArray(tableData) && tableData.length > 0) {
      const keys = Object.keys(tableData[0]); // Get the keys from the first object
      return (
        <Table>
          <thead>
            <TableRow>
              {keys.map((key, index) => (
                <TableHead key={index}>{key}</TableHead>
              ))}
            </TableRow>
          </thead>
          <tbody>
            {tableData.map((item, index) => (
              <TableRow key={index}>
                {keys.map((key, idx) => (
                  <TableCell key={idx}>{item[key]}</TableCell>
                ))}
              </TableRow>
            ))}
          </tbody>
        </Table>
      );
    } else {
      return null;
    }
  };

  const handleDeleteQuery = () => {
    setSearchQuery('')
    setshowDraft([]);
    setImageUrl(null)
    setTableData(null)
    setSelectedDraft(null)
  }

  const handleOnchange = (e) => {
    setSearchQuery(e.target.value)
    setshowDraft([]);
  }
  
  return (
    <Wrapper>
      <ContentWrapper>
        <InputWrapper>
          <Input
            type='text'
            value={searchQuery}
            onChange={(e) => handleOnchange(e)}
          ></Input>
          {searchQuery && <Icon onClick={handleDeleteQuery}>{CrossIcon}</Icon>} 
           <ArrowIcon onClick={handleSearch}>{HorizontalArrowIcon}</ArrowIcon>
           <div onMouseOver={() => setInfotext(true)}  onMouseLeave={()=>setInfotext(false)}>
            {QuestionIcon}
            {infoText && (
              <InfoWrapper>
                Custom analysis means using special tools to find exactly what
                you're looking for.
              </InfoWrapper>
            )}
          </div>
        </InputWrapper>
        {showDraft && showDraft?.length > 0 ? (
          <>
          <DraftWrapper>
            {showDraft?.map((item, index) => (
              <DraftItem
              
                onClick={() => handleDraftClick(item.suggestion,item.is_table)}
                key={index}
              >
                {item.suggestion}
              </DraftItem>
            ))}
          </DraftWrapper>
          </>)
          :
          <></>
          
        }
        {selectedDraft && <div><b>Selected Draft:</b> {selectedDraft}</div>}
      </ContentWrapper>
      {loading === true ? (
        <div style={{ marginTop: '-80px' }}>
          <Lottie
            data-testid='loader'
            animationData={Loader}
            loop={true}
            width='100px'
            height='100px'
          />
        </div>
      ) : (
        <Container>{renderResult()}</Container>
      )}
    </Wrapper>
  );
};

export default CustomAnalysisTab;