import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  CreateChatterIcon,
  AmazonIconSmall,
  ExportIcon,
  ResetIcon,
  FIlterIcon,
  ShareIcon,
  FlipkartIconSmall,
} from '../../../../Common/SvgIcon/CommonSvgIcons';
import OverViewTab from './OverViewTab/OverViewTab';
import SentimentsTab from './SentimentsTab/SentimentsTab';
import CommentsTab from './CommentsTab/CommentsTab';
import InsightsTab from './InsightsTab/InsightsTab';
import {
  companyBasicDetails,
  getPersonalDetails,
  teamMemberList,
} from '../../../../store/redux/slices/tenantSettingSlice';
import Button from '../../../../Common/Button/Button';
import PopUp from '../../../../Common/PopUp/PopUp';
import SmallSelect from '../../../../Common/Select/SmallSelect';
import Loader from '../../../../assests/animation/Loader2.json';
import {
  FilterReportBasedOnDatesChatter,
  chatterList,
  createChatter,
  getReport,
} from '../../../../store/redux/slices/chatterSlice';
import ClusterAnalysisTab from './ClusterAnalysisTab/ClusterAnalysisTab';
import CustomAnalysisTab from './CustomAnalysis/CustomAnalysis';
import GenerateAnalysis from '../../../../components/Generate Analysis/GenerateAnalysis';
import { formatDate, formatDateFilter } from '../../../../utils';
import MyDatePicker from '../../../../components/DatePicker/DatePicker';

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;

  overflow-y: scroll;
  box-sizing: border-box;
  text-align: left;
  font-family: Open Sans;
`;

const HeaderWrapper = styled.div`
  padding: 20px 0 20px 25px;
`;

const Span1 = styled.span`
  display: flex;

  color: #636363;
  font-size: 18px;
  font-family: Montserrat;
  font-weight: 700;
  margin-bottom: 5px;
`;

const Span2 = styled.span`
  display: flex;
  color: #000;
  font-size: 15px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
`;

const StyledContainer = styled.div`
  display: flex;
`;

const ChannelsText = styled.p`
  font-size: 15px;
  color: rgba(0, 0, 0, 0.84);
  font-weight: 600;
  padding-right: 5px;
`;

const Icon = styled.div`
  // display: flex;

  & > svg {
    height: 20px;
    width: min-content;
  }
`;

const TwitterText = styled.p`
  display: flex;
  gap: 4px;
  font-size: 15px;
  color: #000;
  font-weight: 400;
`;

const QueryPara = styled.p`
  display: flex;
  flex-direction: column;
  word-break:break-all;
  color: #6394f0;
  font-size: 15px;
  line-height: 25px;
  margin: 0 0;
  font-weight: 400;
  font-family: 'Open Sans';
   cursor:pointer;
     
  span {
    margin-top: 10px;
  }
   &:hover {
    /* Define hover styles here */
    text-decoration: underline;
  }
`;

const TabHeading = styled.div`
  border-top: 1px solid #d2d2d2;
  border-bottom: 1px solid #d2d2d2;

  display: flex;
  justify-content: space-between;

  & > div {
    color: #000;
    font-size: 18px;
    font-weight: 400;
    padding: 8px;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 14px 0;
  }

  //Active Tab (Monthly or Annual Plan)
  & .activePlanTab {
    color: #353cf7;
    border-bottom: 2px solid #353cf7;
    font-weight: 600;
  }
`;

export const Container = styled.div`
  diaplay: flex;
  flex-direction: row;
  input {
    border-radius: 15px;
    border: 1px solid #a2a2a2;
    background: #fff;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color: rgba(99, 99, 99, 0.7);
    width: 255px;
    height: 35px;
    padding: 7px 20px;
    align-items: center;
    margin-right: 15px;
    outline: none;
  }
`;

export const Label = styled.label`
  display: flex;
  color: #000;
  margin: 20px 0px 5px 28px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 30px;
`;

const TimeDuration = styled.div`
  background: #fff;
  margin: 10px 0;
  width: max-content;

  display: flex;
  padding: 12px 20px;
  gap: 15px;

  input {
    width: 150px;
    height: 40px;

    border-radius: 8px;
    border: 1px solid #d9d9d9;
    background: #fff;
    box-shadow: 0px 0px 8px 0px rgba(162, 162, 162, 0.24);
    ${'' /* text-align: center; */}
    padding: 1px 8px;

    color: #636363;

    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
  }
  & > div {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    gap: 2px;

    & > span:first-child {
      color: #000;
      font-weight: 600;
      margin-top: -2px;
    }
    & > span:last-child {
      color: #636363;
      font-weight: 400;
      margin-bottom: -2px;
    }
  }
`;

const Line = styled.div`
  margin-top: 25px;
  margin-bottom: 5px;
  height: 1px;
  width: 100%;
  background: #dddddd;
`;

const ButtonDiv = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  margin: 45px 20px 0px 0px;

  font-family: Open Sans;
  font-weight: 600;
  font-size: 20px;

  span {
    cursor: pointer;
    width: 110px;
    height: 30px;
    text-align: center;
    justify-content: center;
    border-radius: 10px;
    background: #353cf7;
    color: #ffff;
    padding: 7px;
    display: flex;
    gap: 5px 5px;
  }
`;

const ResetButton = styled.span`
  cursor: pointer;
  font-family: Open Sans;
  font-weight: 600;
  font-size: 20px;

  width: 110px;
  height: 30px;
  justify-content: center;
  border-radius: 10px;
  background: #353cf7;
  color: #ffff;
  padding: 7px 5px;
  margin: 20px 0px 0px 0px;
  display: flex;
  gap: 5px 8px;
`;

export const CreatePopupButton = styled.div`

    margin-top: 25px;
    gap: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const DeletePopupWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > p:nth-child(1) {
    color: #636363;
    text-align: left;
    color: #636363;
    font-family: 'Open Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  & > p:nth-child(3) {
    //User name that will be displayed in the popup window
    span {
      color: #222;
    }
  }

  // Cancel and Delete button containers
  & > div:nth-child(3) {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    & > button {
      width: 200px;
      height: 45px;
      margin: 0 10px;
    }
    & > button:nth-child(1) {
      background-color: #fff;
      color: #222;
      width: max-content;
      margin-right: 30px;
      border: 1px #000;
    }
    & > button:nth-child(2) {
      background-color: #fff;
      color: #222;
      width: max-content;
      margin-right: 30px;
      border: 1px solid #000;
      border-radius: 100px;
      padding: 7px 21px;
    }
  }

  & .selectContainer {
    display: flex;
    justify-content: center;
    & > div {
      width: 40%;
    }
  }
`;

export const DivContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 10px 20px 0px;
`;

export const RadioContainer = styled.div`
  margin-left: 10px;
  margin-top: 5px;
  padding-left: 10px;
  text-align: center;
  color: #636363;
  font-family: 'Open Sans';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;

  &>input: nth-child(2) {
    margin-left: 80px;
  }
`;

function Preview({ previewData, job_id }) {
  const [activeTab, setActiveTab] = useState('overViewTab');
  const [showPopup, setShowPopup] = useState(false);
  const [preview, setPreview] = useState(true);
  const [selectedReport, setSelectedReport] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [shareOption, setShareOption] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [reset, setReset] = useState(false);
  const [filteredData, setFilteredData] = useState(null);
  const [filterFlag, setFilterFlag] = useState(false);
  const [view, setView] = useState('');
  const [filterOption, setFilterOption] = useState('');
  const [chatterData, setChatterData] = useState('');
  /* const [loading, setLoading] = useState(true); */

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { companyBasicDetailsData, teamMemberListData } = useSelector(
    (state) => state.tenantSetting
  );
  const { personalDetailsData } = useSelector((state) => state.tenantSetting) || {};

  let reportData = null;
  const { chatterPreviewData } =
    useSelector((state) => state.chatterTracker) || {};
  const loading = useSelector((state) => state.chatterTracker.chatterLoading);
  const hyperlink = selectedReport
    ? selectedReport?.hyperlink
    : previewData?.hyperlink;

  /* const start_Date = formatDate(previewData?.start_date) || '2024/02/01';
  const end_Date = formatDate(previewData?.end_date); */
  useEffect(() => {
    if (personalDetailsData === null) {
      dispatch(getPersonalDetails());
    }
    if (companyBasicDetailsData === null || teamMemberListData === null) {
      dispatch(companyBasicDetails());
      dispatch(teamMemberList());
    }
  }, []);

// console.log(chatterPreviewData,previewData,"chatterPreviewData");
  const updateSearchQuery = (query) => {
    setSearchQuery(query);
  };
  useEffect(() => {
    if (typeof job_id === 'number') {
      dispatch(getReport(job_id)).then((data) => {
        setSelectedReport(data?.payload?.data);
      });
    }
  }, [job_id]);
  
  useEffect(() => {
    if (selectedReport || previewData) {
      // Set start date
      setStartDate((prevStartDate) => {
        const newStartDate = selectedReport
          ? formatDateFilter(selectedReport?.start_date)
          : previewData
            ? formatDateFilter(previewData?.start_date)
            : '2024-01-01';
  
        // Compare and update only if the new date is different
        return prevStartDate !== newStartDate ? newStartDate : prevStartDate;
      });
  
      // Set end date
      setEndDate((prevEndDate) => {
        const newEndDate = selectedReport
          ? formatDateFilter(selectedReport?.end_date)
          : previewData
            ? formatDateFilter(previewData?.end_date)
            : '2024-01-01';
  
        return prevEndDate !== newEndDate ? newEndDate : prevEndDate;
      });
    }
  }, [selectedReport, previewData]);
  

  const formatDate1 = (date = '') => {
    try {
      let dateString = date.split('/');
      return `${dateString[2]}-${dateString[1]}-${dateString[0]}`;
    } catch (err) {
      console.log('hide');
    }
  };

  const initialStartDate = selectedReport ? formatDateFilter(selectedReport?.start_date) : previewData ? formatDateFilter(previewData?.start_date) : '';
  const initialEndDate = selectedReport ? formatDateFilter(selectedReport?.end_date) : previewData ? formatDateFilter(previewData?.end_date) : '';
  // const initialStartDate = formatDate1(selectedReport?.start_date);
  // const initialEndDate = formatDate1(selectedReport?.end_date);
  // if (selectedReport && startDate === '' && endDate === '') {
  //   setStartDate(formatDate1(selectedReport?.start_date));
  //   setEndDate(formatDate1(selectedReport?.end_date));
  // }

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleShareClick = () => {
    setShareOption(!shareOption);
  };

  const handleCreateChatter = () => {
    const productUrl = previewData?.hyperlink;
    const channelName = previewData?.channel;
    const chatterTitle = previewData?.title;
    const startDate = formatDateFilter(previewData?.start_date);
    const endDate = formatDateFilter(previewData?.end_date);
    dispatch(
      createChatter({
        productUrl,
        channelName,
        chatterTitle,
        startDate,
        endDate,
      })
    ).then((data) => {
      dispatch(chatterList());
      setChatterData(data.payload);
      navigate('/chatter/job_' + data.payload.data.job_id);
    });
    setShowPopup(false);
  };

  const dateHandler = (e) => {
    const { name, value } = e;
    const formatDate = (isoString) => {
      const date = new Date(isoString); // Create a Date object from the ISO string
      const year = date.getFullYear(); // Get the year
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Get the month (0-based index, hence +1)
      const day = String(date.getDate()).padStart(2, '0'); // Get the day

      return `${year}-${month}-${day}`; // Return the formatted date
    };


    const formattedDate = formatDate(value);
    if (name === 'firstPostDate') {
      setStartDate(formattedDate);
    } else if (name === 'lastPostDate') {
      setEndDate(formattedDate);

      // const { name, value } = e.target;
      // if (name === 'firstPostDate') {
      //   setStartDate(value);
      // } else if (name === 'lastPostDate') {
      //   setEndDate(value);
      dispatch(
        FilterReportBasedOnDatesChatter({ startDate, endDate: formattedDate, job_id })
      )
        .then((res) => {
          setFilteredData(res.payload.response);
        })
        .catch((error) => {
          console.error('Error:', error); // Handling any errors
        });
    }
    setReset(true);
  };

    const newWindowFunc = () => {
    if(hyperlink){
      window.open(hyperlink, '_blank');
    }
  }
  // console.log(selectedReport,'selectedReport');
  const renderTabContent = () => {
    switch (activeTab) {
      case 'overViewTab':
        return (
          <OverViewTab
            chatterData={previewData}
            plan={companyBasicDetailsData?.plan}
            /* chatterReport={report} */
            selectedReport={selectedReport}
            filteredData={filteredData?.data?.overview_tab}
            formatValue={
              formatValue
            }
            startDate={selectedReport?.start_date || previewData?.start_date}
            endDate={selectedReport?.end_date || previewData?.end_date}
            reset={reset}
            filterOption={filterOption}
            view={view}
            filteredStartDate={startDate}
            filteredEndDate={endDate}
          />
        );
      case 'sentimentsTab':
        return (
          <SentimentsTab
            chatterData={previewData}
            plan={companyBasicDetailsData?.plan}
            /* chatterReport={report} */
            selectedReport={selectedReport}
            filteredData={filteredData?.data}
            reset={reset}
            filterOption={filterOption}
            view={view}
            filteredStartDate={startDate}
            filteredEndDate={endDate}

            formatValue={
              formatValue
            }
            startDate={selectedReport?.start_date || previewData?.start_date}
            endDate={selectedReport?.end_date || previewData?.end_date}
          />
        );
      case 'insightTab':
        return (
          <InsightsTab
            insightTabData={previewData}
            plan={companyBasicDetailsData?.plan}
            /* chatterReport={report} */
            selectedReport={selectedReport}
            filteredData={filteredData?.data}
            reset={reset}
            filterOption={filterOption}
            view={view}
            filteredStartDate={startDate}
            filteredEndDate={endDate}

            formatValue={
              formatValue
            }
            startDate={selectedReport?.start_date || previewData?.start_date}
            endDate={selectedReport?.end_date || previewData?.end_date}
          />
        );
      case 'clusterTab':
        return (
          <ClusterAnalysisTab
            job_id={job_id}
            data={previewData?.data?.cluster_analysis_tab}
            selectedReport={selectedReport?.data?.cluster_analysis_tab}
            // startDate={previewData?.start_date || selectedReport?.start_date}
            // endDate={previewData?.end_date || selectedReport?.end_date}
            filteredData={filteredData?.data.cluster_analysis_tab}
            reset={reset}
            filteredStartDate={startDate}
            filteredEndDate={endDate}
            moduleType={previewData?.channel || selectedReport?.channel}


            formatValue={
              formatValue
            }
            startDate={selectedReport?.start_date || previewData?.start_date}
            endDate={selectedReport?.end_date || previewData?.end_date}
          />
        );
      case 'customTab':
        return (
          <CustomAnalysisTab
            preview_uuid={previewData?.preview_uuid}
            job_id={job_id}
            onUpdateSearchQuery={updateSearchQuery}
            channel={selectedReport?.channel || previewData?.channel}
          />
        );
      case 'commentsTab':
        return (
          <CommentsTab
            job_id={job_id}
            channelName={
              previewData?.channel || selectedReport?.channel
            }
            startDate={initialStartDate}
            endDate={initialEndDate}
            min={initialStartDate}
            max={initialEndDate}
            preview_uuid={previewData?.preview_uuid}
            formatValue={
              formatValue
            }
          />
        );
      default:
        return null;
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleFilter = () => {
    setFilterFlag(!filterFlag);
  };

  const hadleFilterChange = (selectedOption) => {
    setFilterOption(selectedOption);
  };

  const handleClear = () => {
    setFilterOption(null);
    setView(null);
  };

  const handleApply = () => {
    setFilterFlag(!filterFlag);
  };

  const handleReset = () => {
    setStartDate(initialStartDate);
    setEndDate(initialEndDate);

    setStartDate((prevStartDate) => {

      const newStartDate = selectedReport
        ? formatDateFilter(selectedReport?.initial_start_date)
        : previewData
          ? formatDateFilter(previewData?.initial_start_date)
          : '2024-01-01';

      // You can now compare newStartDate with prevStartDate if needed
      return prevStartDate !== newStartDate ? newStartDate : prevStartDate;




    });
    setEndDate((prevEndDate) => {

      const newEndDate = selectedReport
        ? formatDateFilter(selectedReport?.initial_end_date)
        : previewData
          ? formatDateFilter(previewData?.initial_end_date)
          : '01-01-2024';

      // You can now compare newStartDate with prevStartDate if needed
      return prevEndDate !== newEndDate ? newEndDate : prevEndDate;




    });
    setReset(false);
    setFilteredData(null)
  };


  const formatValue = personalDetailsData?.app_preference?.date_format === "dd/mm/yyyy" ? 'dd-MM-yyyy' :
    personalDetailsData?.app_preference?.date_format === "mm/dd/yyyy" ? 'MM-dd-yyyy' :
      personalDetailsData?.app_preference?.date_format === "yyyy/mm/dd" ? 'yyyy-MM-dd' :
        'dd-MM-yyyy';

  return (
    <FlexWrapper>
      <HeaderWrapper>
        {shareOption && (
          <GenerateAnalysis
            title={previewData?.title || selectedReport?.title}
            open={shareOption}
            onClose={() => setShareOption(false)}
            moduleType={selectedReport?.channel == 'flipkart' ? 'Flipkart' : 'Amazon'}
            job_id={job_id}
            searchQuery={searchQuery}
            reportData={selectedReport}
            imageResponse={localStorage.getItem('imageResponse_chatter')}
            tableResponse={'tableData_chatter'}
          />
        )}
        {filterFlag && (
          <PopUp onClose={handleClosePopup}>
            <DeletePopupWrapper>
              <p>All Filters</p>
              <DivContainer>
                <SmallSelect
                  options={[
                    'Bar Graph',
                    'Donut Chart',
                    'Pie Chart',
                    'Funnel Chart',
                  ]}
                  onChange={(selectedOption) =>
                    hadleFilterChange(selectedOption)
                  }
                  placeholder='Visual View'
                />
                <RadioContainer>
                  <input
                    type='radio'
                    name='viewType'
                    value='horizontal'
                    onChange={(e) => setView(e.target.value)}
                  />
                  Horizontal View
                  <input
                    type='radio'
                    name='viewType'
                    value='vertical'
                    onChange={(e) => setView(e.target.value)}
                  />{' '}
                  Vertical View
                </RadioContainer>
              </DivContainer>
              <div>
                <Button value={'Cancel'} onClick={() => setFilterFlag(false)} />
                <Button value={'Clear All'} onClick={handleClear} />
                <Button value={'Apply'} onClick={handleApply} />
              </div>
            </DeletePopupWrapper>
          </PopUp>
        )}
        <Title>
          <Span1>
            {
              previewData?.title.charAt(0).toUpperCase() + previewData?.title.slice(1) ||
              selectedReport?.title.charAt(0).toUpperCase() + selectedReport?.title.slice(1)
            }
          </Span1>
          {preview && !selectedReport && (
            <Button
              style={{ width: '15%', height: '42px' }}
              value='Create Chatter'
              onClick={() => setShowPopup(true)}
            />
          )}
        </Title>
        <Span2>{previewData?.description || selectedReport?.description || ''} </Span2>
        <StyledContainer>
          <ChannelsText>Channel: </ChannelsText>

          {previewData?.channel === 'amazon' ||
            selectedReport?.channel === 'amazon' ? (
            <TwitterText>
              <Icon>{AmazonIconSmall}</Icon>
              Amazon{' '}
            </TwitterText>
          ) : (
            <TwitterText>
              <Icon>{FlipkartIconSmall}</Icon>
              Flipkart{' '}
            </TwitterText>
          )}
        </StyledContainer>
        <QueryPara onClick={()=>newWindowFunc()}>
          {hyperlink}
          {/* <span>Chatter Created on March 22 on 1:51 PM</span> */}
        </QueryPara>
        <Line />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <TimeDuration>
            <div>
              <span>First Post Date:</span>
              {/* <input
                data-testid='end-date'
                type='date'
                name='firstPostDate'
                value={startDate || initialStartDate}
                onChange={(e) => dateHandler(e)}
                min={initialStartDate}
                max={initialEndDate}
              /> */}
              <MyDatePicker
                name='lastPostDate'
                value={startDate || initialStartDate}
                dateHandler={dateHandler}
                format={
                  formatValue
                }
                min={initialStartDate}
                max={initialEndDate}
              />
            </div>
            <div>
              <span>Last Post Date:</span>
              {/* <input
                data-testid='end-date'
                type='date'
                name='lastPostDate'
                value={endDate || initialEndDate}
                onChange={(e) => dateHandler(e)}
                min={startDate}
                max={initialEndDate}
              /> */}
              <MyDatePicker
                name='lastPostDate'
                value={endDate || initialEndDate}
                dateHandler={dateHandler}
                format={
                  formatValue
                }
                min={startDate}
                max={initialEndDate}
              />
            </div>
            <ResetButton onClick={handleReset}>Reset{ResetIcon}</ResetButton>
          </TimeDuration>

          <ButtonDiv>
            <span onClick={handleFilter}>Filters{FIlterIcon}</span>
            {preview === false || selectedReport ? (
              <span onClick={handleShareClick}>Share{ShareIcon}</span>
            ) : null}
          </ButtonDiv>
        </div>
      </HeaderWrapper>
      {showPopup && (
        <PopUp onClose={handleClosePopup}>
          <DeletePopupWrapper>
            <div>

            {CreateChatterIcon}
            </div>
            <p>Create Chatter</p>
            <p>
              Are you sure you want to create
              <span> Chatter?</span>
            </p>
            <CreatePopupButton>
              <Button onClick={handleClosePopup} value={'Cancel'} style={{width:'220px'}} />
              <Button onClick={handleCreateChatter} value={'Confirm'} style={{width:'220px'}} />
            </CreatePopupButton>
          </DeletePopupWrapper>
        </PopUp>
      )}
      <TabHeading>
        <div
          className={activeTab === 'overViewTab' ? 'activePlanTab' : ''}
          onClick={() => handleTabClick('overViewTab')}
        >
          Overviews
        </div>
        <div
          className={activeTab === 'sentimentsTab' ? 'activePlanTab' : ''}
          onClick={() => handleTabClick('sentimentsTab')}
        >
          Sentiments
        </div>
        <div
          className={activeTab === 'insightTab' ? 'activePlanTab' : ''}
          onClick={() => handleTabClick('insightTab')}
        >
          Insights
        </div>
        <div
          className={activeTab === 'clusterTab' ? 'activePlanTab' : ''}
          onClick={() => handleTabClick('clusterTab')}
        >
          Cluster Analysis
        </div>
        <div
          className={activeTab === 'customTab' ? 'activePlanTab' : ''}
          onClick={() => handleTabClick('customTab')}
        >
          Custom Analysis
        </div>
        <div
          className={activeTab === 'commentsTab' ? 'activePlanTab' : ''}
          onClick={() => handleTabClick('commentsTab')}
        >
          Reviews
        </div>
      </TabHeading>
      <div style={{ display: 'flex',marginBottom:'30px' }}>{renderTabContent()}</div>
    </FlexWrapper>
  );
}

export default Preview;