import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const MyDatePicker = ({value,dateHandler,format='dd-MM-yyyy',min,max,name}) => {


  return (
    <div>
      <DatePicker
        selected={value}
        onChange={(date) => dateHandler({value:date,name})}
        dateFormat={format} // This is how it will display in the input field
        minDate={min}  // Set minimum date
        maxDate={max}  // Set maximum date
      />
    </div>
  );
};

export default MyDatePicker;
