import React, { Children } from 'react';
import styled from 'styled-components';
import { DeleteIcon } from '../../Common/SvgIcon/CommonSvgIcons';
import Button from '../Button/Button';

const BlackDrop = styled.div`
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0%;
  left: 0%;

  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(1.5px);
  z-index: 10;
`;

const Content = styled.div`
  & > div {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 45%;
    max-width: 80%;

    max-height: 85%;

    border: 1px solid #d9d9d9;
    background: #fff;
    border-radius: 15px;

    z-index: 1000000000;
    padding: 40px 0 20px 0;

    color: #636363;
    text-align: center;
    font-size: 16px;
    font-weight: 600;

    padding: 30px;
    overflow: hidden;
  }
`;

const PopUp = ({ onClose, children }) => {
  return (
    <>
      <BlackDrop onClick={onClose} />
      <Content>{children}</Content>
    </>
  );
};

export default PopUp;
