import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  // height: 100vh;
  flex-direction: column;
  overflow-y: scroll;
  padding:0px 20px 20px 25px;
`;

export const Text = styled.span`
  width: 965px;
  height: 51px;
  display: flex;
  text-align: left;
  margin: 40px 0px 10px 0px;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 18px;
  //line-height: 22px;
  color: #636363;
`;
export const PreviewBtn = styled.button`
  background: #ffff;
  color: #636363;
  border: 1px solid rgba(162, 162, 162, 0.6);
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.1));
  border-radius: 68px;
  padding: 8px 24px;

  font-size: 16px;
  font-weight: 600;
  font-family: Montserrat;
  cursor: pointer;

  // background: #353cf7;
  // color: #fff;
`;
export const TopicText = styled.div`
  //display:inline-block;
  display: flex;
  width: 270px;
  height: 22px;
  margin: 0px 0px 10px 0px;
  text-align: left;

  font-family: 'Montserrat';
  //font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-transform: capitalize;
  color: #353cf7;
`;

export const FlexWrapper = styled.div`
  display: flex;
  margin-left: 0px;
  margin-top:12px;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 15px 0px 0px 0px;
`;

export const DateDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap:15px;
  align-items:flex-end;
`;

export const Container = styled.div`


& input {
    border-radius: 15px;
    border: 1px solid #a2a2a2;
    background: #fff;

    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color: rgba(99, 99, 99, 0.7);

    width: 255px;
    height: 35px;
    padding: 7px 20px;
    align-items: center;
    // margin-right: 15px;

    outline: none;
  }
`;

export const ChannelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  // width: 50%;
  margin: 15px 0px 50px 0px;
`;

export const Label = styled.label`
  display: flex;
  color: #000;
  margin: 0px 0px 5px 0px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;

  & p {

    margin:0px;
    color:rgb(252, 103, 88);
    margin-left:5px;
  }
`;

export const DropdownContainer = styled.div`
  display: flex;
  // min-height: 100px;
  margin: 10px 0px 25px 0px;
  width:50%;
`;
export const LoadingWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.1);

  & > div {
    position: fixed;
    top: 25%;
    left: 25%;
  }

  & svg {
    transform: scale(3);
  }
`;


export const SubLabel = styled.span`
  width: 485px;
  height: 20px;
  display: flex;
  margin: 5px 0px 10px 0px;
  font-size: 14px;
  color: #636363;
  font-weight: 400;
  margin-top: 5px;
`;

export const ErrorFont = styled.p`
color:red;

text-align: start;
margin: 0px;
`;

export const ProductWrapper = styled.div`
  width: 98%;
  margin: 0px 0px 80px 0px;
  display: flex;
  flex-direction: column;
  height: 185px;
  border: 1px solid #ddd;
  padding: 0px 10px 10px 10px;
  border-radius: 15px;
  background-color: #fff;
  cursor:pointer;

  h3 {
    height: 10px;
    text-align: left;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
  }
`;

export const RatingSpan = styled.span`
  gap: 6px;
  margin: 20px 0px 0px 0px;
  text-align: left;
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Price = styled.p`
  height: 15px;
  font-family: Montserrat;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
`;

export const RealPrice = styled.p`
  margin-top: 25px;
  color: #565959;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  text-decoration: line-through !important;
`;

export const DescWrapper = styled.span`
  color: #000;
  text-align: left;
  margin-left: 0px;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-bottom:10px;
`;

export const DivWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ProductUrl = styled.a`
  color: #3D43D2;
  display: flex;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`;
 export const DeletePopupWrapper = styled.div`
  line-height: 1.3rem;

  & > svg {
    width: 60px;
    height: 60px;
  }

  & > p:nth-child(2) {
    color: #fc6758;
  }

  & > p:nth-child(3) {
    //User name that will be displayed in the popup window
    span {
      color: #222;
    }
  }

  // Cancel and Delete button containers
  & > div {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    & > button {
      width: 200px;
      height: 45px;
      margin: 0 10px;
    }
    & > button:nth-child(1) {
      background-color: #fff;
      color: #222;
      width: min-content;
      margin-right: 30px;
    }
  }

  & .selectContainer {
    display: flex;
    justify-content: center;
    & > div {
      width: 40%;
    }
  }
`;