import tenantAxiosInstance from '../utils/tenantAxiosInstance';

const API_URL = process.env.REACT_APP_BACKEND_URL;
const version = 'v1';
const ProductListAmazon = (searchQuery, selectedCountry,tenant_id,user_id) => {
  const productData = {
    product_search: searchQuery,
    country: selectedCountry,
  };
  return tenantAxiosInstance
    .post(API_URL + `search_amazon/${tenant_id}/${user_id}`, productData)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.error('Request Error:', error); // Log any request errors
      throw error; // Rethrow the error to handle it in your component
    });
};

const ProductListFlipkart = (searchQuery,selectedCountry,tenant_id,user_id) => {
  const productData = {
    product_search: searchQuery,
    country: selectedCountry,
  };
  return tenantAxiosInstance
    .post(API_URL + `search_flipkart/${tenant_id}/${user_id}`, productData)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.error('Request Error:', error); // Log any request errors
      throw error; // Rethrow the error to handle it in your component
    });
};


const ChatterPreview = async (
  productUrl,
  channelName,
  chatterTitle,
  startDate,
  endDate,
  tenant_id,
  user_id,
  description,
) => {
  
  const previewData = {
    hyperlink: productUrl,
    channel_name: channelName,
    chatter_title: chatterTitle,
    start_date: startDate || null,
    end_date: endDate || null,
    'description':description || ''
  };
  const preview_uuid = await tenantAxiosInstance
    .post(API_URL + `createpreview/${tenant_id}/${user_id}`, previewData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      localStorage.setItem('uuid', response.data.preview_uuid);
      return response.data; // Assuming your response structure has a 'data' field
    })
    .catch((error) => {
      console.error('Request Error:', error);
      throw error;
    });

  const fetchPreviewData = () => {
    return tenantAxiosInstance
    
    .post(API_URL + `${version}/report/preview/${tenant_id}/${user_id}`, {
      // .post(API_URL + `preview/fetch/${tenant_id}/${user_id}/`, {
        preview_uuid: preview_uuid.preview_uuid,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error('Request Error:', error);
        throw error;
      });
  };

  let actualResponse;
  const refreshPreviewData = async () => {
    actualResponse = await fetchPreviewData();
    if (typeof actualResponse === 'object') {
      return actualResponse;
    } 
    // else if(){
    //   await new Promise((resolve) => setTimeout(resolve, 8000));
    //   return refreshPreviewData();
    // }
    else {
      let status = actualResponse.split(" ");


      status.shift();

      // Join the remaining words back into a string
      let resultString = status.join(" ");


      if (resultString && resultString == 'still in progress') {

        await new Promise((resolve) => setTimeout(resolve, 8000));
        return refreshPreviewData();
      } else if (resultString && resultString == 'has failed') {

        localStorage.removeItem('fetch_uuid_for_topicPreview');
        localStorage.removeItem('topicPreviewLoading');

        return { data: 'Has Failed' };
      }
    }




  };
  const dataRes = await refreshPreviewData();
  return dataRes;
};

const reviewsForChatterPreview = (tenant_id, user_id,preview_uuid) => {
  // const preview_uuid = localStorage.getItem('uuid');
  return tenantAxiosInstance
    .post(
      API_URL + `${version}/reviews/preview/${tenant_id}/${preview_uuid}`, {}
      // API_URL + `previewchatter/getall/reviews/${tenant_id}/${preview_uuid}/`, {}
    )
    .then((response) => {

      return response.data;
    })
    .catch((error) => {
      console.error('Request Error:', error);
      throw error;
    });
};

const CreateChatter = (
  productUrl,
  channelName,
  chatterTitle,
  startDate,
  endDate,
  tenant_id,
  user_id
) => {
  const chatterData = {
    hyperlink: productUrl,
    channel_name: channelName,
    chatter_title: chatterTitle,
    start_date: startDate,
    end_date: endDate,
  };

  return tenantAxiosInstance
    .post(API_URL + `chatter/create/${tenant_id}/${user_id}`, chatterData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data; // Assuming your response structure has a 'data' field
    })
    .catch((error) => {
      console.error('Request Error:', error);
      throw error;
    });
};

const chatterReviewsForDownload = (tenant_id,job_id,email_id) => {
  return tenantAxiosInstance
  
  .post(API_URL + `v1/reviews/job/${tenant_id}/${job_id}`, {})
  // .get(API_URL + `report/reviews/${tenant_id}/${job_id}/${email_id}/`, {})
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    console.error('Request Error:', error);
    throw error;
  });
}

const reviewsForChatter = (tenant_id,job_id) => {
  /* const jobId = localStorage.getItem('jobId');  */
  return tenantAxiosInstance
    .post(API_URL + `${version}/reviews/job/${tenant_id}/${job_id}`, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Request Error:', error);
      throw error;
    });
};

const reviewsFilterChatter = (tenant_id,job_id, dates) => {
  /* const jobId = localStorage.getItem('jobId');  */
  return tenantAxiosInstance
  
  .post(API_URL + `${version}/reviews/job/${tenant_id}/${job_id}`, dates)
    // .post(API_URL + `chatter/getall/reviews/${tenant_id}/${job_id}`, dates)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Request Error:', error);
      throw error;
    });
};

const reviewsFilterChatterPreview = (tenant_id,preview_uuid, dates) => {
  /* const jobId = localStorage.getItem('jobId');  */
  return tenantAxiosInstance
    .post(API_URL + `/previewchatter/getall/reviews/${tenant_id}/${preview_uuid}`, dates)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Request Error:', error);
      throw error;
    });
};


const ChatterList = (tenant_id, user_id) => {
  return tenantAxiosInstance
    .get(API_URL + `chatter/active/list/${tenant_id}/${user_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Request Error:', error);
      throw error;
    });
};

const GetReport = (tenant_id, job_id) => {
  return tenantAxiosInstance
    .post(API_URL + `${version}/report/job/${tenant_id}/${job_id}`,{})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Request Error:', error);
      throw error;
    });
};

const CustomAnalysisService = (
  searchQuery,
  tenant_id,
  user_id,
  jobId,
  uuid,
  channelName
) => {
  const customAnalysisData = {
    job_id: jobId ? jobId : null,
    preview_uuid: jobId ? null : uuid,
    channel_name: channelName,
    query: searchQuery,
  };
  return tenantAxiosInstance
    .post(
      API_URL + `chatter/topic/custom_analysis/draft/${tenant_id}/${user_id}`,
      customAnalysisData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then((response) => {
      return response.data; // Assuming your response structure has a 'data' field
    })
    .catch((error) => {
      console.error('Request Error:', error);
      throw error;
    });
};

const CustomAnalysis = (
  is_table,
  searchQuery,
  tenant_id,
  user_id,
  jobId,
  uuid,
  channelName
) => {
  const customAnalysisData = {
    job_id: jobId ? jobId : null,
    preview_uuid: jobId ? null : uuid,
    channel_name: channelName,
    query: searchQuery,
    is_table:is_table
  };
  return tenantAxiosInstance
    .post(
      API_URL + `chatter/topic/custom_analysis/${tenant_id}/${user_id}`,
      customAnalysisData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then((response) => {
      return response.data; // Assuming your response structure has a 'data' field
    })
    .catch((error) => {
      console.error('Request Error:', error);
      throw error;
    });
};


const ChatterHibernate = (tenant_id, user_id, job_id) => {
  return tenantAxiosInstance
    .delete(API_URL + `chatter/hibernate/${tenant_id}/${user_id}/${job_id}`)
    .then((response) => {
      return response.data;
    });
};

const ChatterDelete = (tenant_id, user_id, job_id) => {
  return tenantAxiosInstance
    .delete(
      API_URL + `chatter/permanentdelete/${tenant_id}/${user_id}/${job_id}`
    )
    .then((response) => {
      return response.data;
    });
};

const ChatterActive = (tenant_id, user_id, job_id) => {
  return tenantAxiosInstance
    .put(API_URL + `chatter/hibernatetoactive/${tenant_id}/${user_id}/${job_id}`)
    .then((response) => {
      return response.data;
    });
};

const GetReportBasedOnDateChatter = (startDate, endDate, tenant_id, job_id) => {
  return tenantAxiosInstance
    .post(
      
      API_URL + `${version}/report/job/${tenant_id}/${job_id}`,
      // API_URL + `get_report_chatter/based_on_dates/${tenant_id}/${job_id}/`,
      {
        start_date: startDate,
        end_date: endDate,
      }
    )
    .then((response) => {
      return response.data;
    });
};

const ChatterService = {
  ProductListAmazon,
  ProductListFlipkart,
  ChatterPreview,
  chatterReviewsForDownload,
  reviewsForChatterPreview,
  reviewsFilterChatter,
  reviewsFilterChatterPreview,
  CreateChatter,
  ChatterList,
  GetReport,
  CustomAnalysisService,
  ChatterHibernate,
  ChatterDelete,
  ChatterActive,
  GetReportBasedOnDateChatter,
  reviewsForChatter,
  CustomAnalysis
};

export default ChatterService;
