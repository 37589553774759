import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Input from '../../../SettingPage/Input.jsx';
import ChannelDropdown from './ShoppingChannels/ChannelDropdown.jsx';
import Description from '../../../../Common/DescriptionInput/Description.jsx';
import Button from '../../../../Common/Button/Button.jsx';
import {
  CrossIcon,
  DownArrowIcon,
  FilledStar,
  HalfStar,
  DeleteProductIcon,
} from '../../../../Common/SvgIcon/CommonSvgIcons.js';
import PoPup from './ShoppingChannels/Popup.jsx';
import useFormattedMessage from '../../../../hooks/useFormattedMessage.js';
import {
  Wrapper,
  Text,
  TopicText,
  FlexWrapper,
  DateDiv,
  Container,
  Label,
  DescriptionWrapper,
  ChannelWrapper,
  SubLabel,
  DropdownContainer,
  ProductWrapper,
  RatingSpan,
  PriceWrapper,
  Price,
  RealPrice,
  DescWrapper,
  DivWrapper,
  ProductUrl,
  DeletePopupWrapper,
  PreviewBtn,
  ErrorFont,
  LoadingWrapper,
} from './ChatterBuilder.styled.js';
import Lottie from 'lottie-react';
import PopUp from '../../../../Common/PopUp/PopUp.jsx';
import Preview from '../ChatterPreview/Preview.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { setChatterPreviewData } from '../../../../store/redux/slices/previewDataSlice.js';
import { chatterPreview } from '../../../../store/redux/slices/chatterSlice.js';
import Loader from '../../../../assests/animation/Loader2.json';
import { setMessage } from '../../../../store/redux/slices/alertMessageSlice.js';
import CustomNotification from '../../../../Common/CustomNotificationButton/CutomNotification.jsx';
import moment from 'moment/moment';

function ChatterTrackerBuilder({ setPreviewData, setLoading }) {
  const [chatterTitle, setChatterTitle] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [desc, setDesc] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showDeletePopup, setShowDeletePupup] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState('');
  const [titleError, setTitleError] = useState('');
  const [productError, setProductError] = useState('');

  const [isDateValid, setIsDateValid] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const characterLimit = 50;
  const descLimit = 250;

  const t = useFormattedMessage();

  const handleInputChange = (e) => {
    
    const newValue = e.target.value;

    if (newValue != '') {
      setTitleError('');
    }
    setChatterTitle(e.target.value);
  };

  const openPopup = (selectedOptionValue) => {
    setIsPopupOpen(true);
    setSelectedChannel();
    setSelectedChannel(selectedOptionValue);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const updateSelectedProduct = (product) => {
    
    if (product != '') {
      setProductError('');
    }
    setSelectedProduct(product);
    closePopup(); // Close the popup after selecting a product
  };

  useEffect(() => {
    // Validate dates whenever they change
    if (startDate && endDate) {
      const isValid = moment(startDate).isBefore(moment(endDate));
      setIsDateValid(isValid);
    }
  }, [startDate, endDate]);

  function generateStarRating(rating) {
    const stars = [];
    /* const filledStar = FilledStar;
    const halfStar = HalfStar; */
    const roundedRating = Math.round(rating);
    for (let i = 0; i < rating; i++) {
      if (i < roundedRating) {
        stars.push(FilledStar);
      } else if (rating % 1 !== 0) {
        stars.push(HalfStar);
      }
    }
    return stars;
  }

  const removeProduct = () => {
    if (selectedProduct) {
      setSelectedProduct(null); // Clear the selected product
      /* localStorage.removeItem('selectedProduct'); */
    }
    setShowDeletePupup(false);
  };

  const handleClosePopup = () => {
    setShowDeletePupup(false);
  };

  const loading = useSelector((state) => state.chatterTracker.chatterLoading);
  const handleUrlClick = async () => {
    if (selectedProduct) {
      /* const productUrl =
        'https://www.amazon.in/Apple-iPhone-Pro-Max-256/dp/B0CHX1K2ZC/ref=sr_1_1_sspa?crid=C682BG3HP1W0&keywords=iphone+14+pro+max&qid=1700207602&sprefix=%2Caps%2C224&sr=8-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&psc=1'; */
      const channelName = selectedChannel === 'Flipkart' ? 'flipkart' : 'amazon'; // Adjust based on your logic
      const productUrl = selectedProduct.url;
      try {
        setLoading(true);
        // Dispatch the chatterPreview action with a callback
        const data = await dispatch(
          chatterPreview({
            productUrl,
            channelName,
            chatterTitle,
            startDate,
            endDate,
            "description":desc,
          })
        )
        // dispatch(
        //   setMessage({
        //     message: (
        //       <CustomNotification
        //         message='Preview Generated successfully! Click Confirm to view preview.'
        //         // onConfirm={() => {
        //           // setPreviewData(data.payload.data);
        //         //   navigate('/chatter/view');
        //         // }}
        //       />
        //     ),
        //     timer: 10000,
        //     type: 'success',
        //     /*  onConfirm: () => {
        //     setPreviewData(data.payload.data)
        //     navigate('/chatter/view');
        //    } */
        //   })
        // )
          setPreviewData(data.payload.data)
          // navigate('/chatter/view')
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error if needed
      } finally {
        setLoading(false);
      }
    }
  };

  const handleVerify = () => {

    let activeError = false;
    if (chatterTitle === '') {

      activeError = true;
      setTitleError('Chatter Title is required');
    }
    if (!selectedProduct) {
      activeError = true;
      setProductError('Select Any One Product')
    }


    if (activeError) {
      return false;
    }
    handleUrlClick()

  }
  // 
  return (
    <Wrapper>
      <Text>{t('CHATTER_DESCRIPTION')}</Text>

      <div style={{ display: 'flex', marginTop: '15px', alignItems: 'center', justifyContent: 'space-between' }}>
        <TopicText> {t('CHATTER_HEAD')}</TopicText>
        <PreviewBtn onClick={handleVerify}>Preview</PreviewBtn>
      </div>
      <FlexWrapper>
        <div style={{ width: '65%' }}>
          <Input
            require={true}
            name='Topic'
            placeholder='Enter chatter title'
            label={'Chatter Title'}
            customStyle={{ width: '95%' }}
            onChange={handleInputChange}
            characterLimit={characterLimit}
            value={chatterTitle}
            error={titleError}
            limit={50}
            maxLength={50}
          />
        </div>
        <DateDiv>
          <Container>
            <Label>Start Date</Label>
            <input
              data-testid='start-date'
              type='date'
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            {!isDateValid && <p style={{ color: 'red', marginTop: 0, fontSize: 12, textAlign: 'start' }}>Start date must be before end date</p>}
          </Container>
          <Container>
            <Label>End Date</Label>
            <input
              data-testid='end-date'
              type='date'
              value={endDate}
              min={startDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </Container>
        </DateDiv>
      </FlexWrapper>
      <DescriptionWrapper>
        <Description
          label={'Description'}
          placeholder='Enter a brief description specifying the chatter’s objective...'
          characterLimit={descLimit}
          type="text"
          value={desc}
          onChange={(e) => {
            setDesc(e.target.value);
          }}
        />
      </DescriptionWrapper>
      {showDeletePopup && (
        <PopUp onClose={handleClosePopup}>
          <DeletePopupWrapper>
            {DeleteProductIcon}
            <p>Remove Product</p>
            <p>
              Are you sure you want to remove
              <span> Product?</span>
              <br /> <br />
              This action cannot be undone
            </p>
            <div>
              <Button onClick={handleClosePopup} value={'Cancel'} />
              <Button onClick={removeProduct} value={'Remove'} />
            </div>
          </DeletePopupWrapper>
        </PopUp>
      )}
      <ChannelWrapper>
        <Label>{t('CHANNELS_HEADER')} <p>*</p></Label>
        <SubLabel>Select any one channel for chatter creation</SubLabel>
        <ErrorFont >{productError}</ErrorFont >
        <DropdownContainer>
          <ChannelDropdown onOptionSelect={openPopup} />
        </DropdownContainer>
        {selectedProduct &&
          <>
            {loading ? (
              <LoadingWrapper>
                <Lottie
                  animationData={Loader}
                  loop={true}
                  width='5px'
                  height='5px'
                />
              </LoadingWrapper>
            ) :
              <ProductWrapper>
                <DivWrapper>
                  <h3>{selectedProduct?.title}</h3>
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => setShowDeletePupup(true)}
                  >
                    {CrossIcon}
                  </span>
                </DivWrapper>
                <RatingSpan>
                  {generateStarRating(selectedProduct?.rating)} {DownArrowIcon}
                  <span style={{ margin: '0px', color: '#007185' }}>
                    {selectedProduct?.rating_count}
                  </span>
                </RatingSpan>
                <PriceWrapper>
                  <Price style={{ color: '#B12704' }}>
                    {' '}
                    {selectedProduct?.price}
                  </Price>
                  <RealPrice>
                    {selectedProduct?.real_price
                      ? selectedProduct?.real_price
                      : ''}
                  </RealPrice>
                </PriceWrapper>
                <ProductUrl>{selectedProduct?.url}</ProductUrl>
              </ProductWrapper>
            }
          </>
        }
      </ChannelWrapper>
      <PoPup
        isOpen={isPopupOpen}
        onClose={closePopup}
        onProductSelect={updateSelectedProduct}
        selectedChannel={selectedChannel}
      />
    </Wrapper>
  );
}

export default ChatterTrackerBuilder;
